const titles = {
	OOW: {
		en: 'Officer of the Watch'
	},
	AC: {
		en: 'Air Controller'
	},
	FDO: {
		en: 'Flight Deck Officer'
	},
	FDD: {
		en: 'Flight Deck Director'
	},
	SUR: {
		en: 'Lashing crew'
	},
	REM: {
		en: 'Rescuer'
	},
	CREW: {
		en: 'Crew'
	}
};

let crewData = {
	cardTypes: ['OOW', 'AC', 'FDO', 'FDD', 'CREW'],

	experienceTypes: {
		OOW: {
			title: titles.OOW,
			label: {
				en: 'OOW experience'
			}
		},
		AC: {
			title: titles.AC,
			label: {
				en: 'AC experience'
			}
		},
		FDO: {
			title: titles.FDO,
			label: {
				en: 'FDO experience'
			}
		},
		FDD: {
			title: titles.FDD,
			label: {
				en: 'FDD experience'
			}
		},
		SUR: {
			title: titles.SUR,
			label: {
				en: 'SUR experience'
			}
		},
		REM: {
			title: titles.REM,
			label: {
				en: 'REM experience'
			}
		}
	},
	issueTypes: {
		persistent: {
			en: 'Persistent'
		},
		complacent: {
			en: 'Complacent'
		},
		restless: {
			en: 'Restless'
		},
		confident: {
			en: 'Overconfident'
		},
		pressure: {
			en: 'Pressure'
		},
		distraction: {
			en: 'Distraction'
		},
		enclosed: {
			en: 'Enclosed'
		},
		fatigue: {
			en: 'Fatigue'
		},
		stress: {
			en: 'Stress'
		},
		introvert: {
			en: 'Introvert'
		},
		autocratic: {
			en: 'Autocratic'
		},
		inexperienced: {
			en: 'Inexperienced'
		},
		uncertainty: {
			en : 'Uncertainty'
		},
		cautious: {
			en: 'Cautious'
		},
		submissive: {
			en: 'Submissive'
		},
		stubborn: {
			en: 'Stubborn'
		}

	},
	crewSlots: [
		{
			id: 1,
			type: 'OOW',
			cardTypes: ['OOW'],
			title: titles.OOW,
			experienceId: 'OOW'
		},
		{
			id: 2,
			type: 'AC',
			cardTypes: ['AC'],
			title: titles.AC,
			experienceId: 'AC'
		},
		{
			id: 3,
			type: 'FDO',
			cardTypes: ['FDO'],
			title: titles.FDO,
			experienceId: 'FDO'
		},	
		{
			id: 4,
			type: 'FDD',
			cardTypes: ['FDD'],
			title: titles.FDD,
			experienceId: 'FDD'
		},
		{
			id: 5,
			type: 'SUR',
			cardTypes: ['CREW'],
			title: titles.SUR,
			experienceId: 'SUR'
		},
		{
			id: 6,
			type: 'REM',
			cardTypes: ['CREW'],
			title: titles.REM,
			experienceId: 'REM'
		},
	],

	availableCrew: [
		{
			id: 1,
			type: 'OOW',
			cardType: 'OOW',
			name: {
				en: 'Michael'
			},
			gender: 'm',
			age: 35,
			title: titles.OOW,
			description: {
				en: 'Michael is an experienced OOW with a good overview of the ship. He is always well-rested for his shifts and very popular amongst most of the crew. For the past six months, he has been in love with the navigator Pia who does not seem to reciprocate his feelings. When she is around, Michael tends to be overly brave.'
			},
			experiences: [
				{id: 'OOW', value: 4},
			],
			issues: ['confident']
		},
		{
			id: 2,
			type: 'OOW',
			cardType: 'OOW',
			name: {
				en: 'Peter'
			},
			gender: 'm',
			age: 49,
			title: titles.OOW,
			description: {
				en: 'Peter is happily married to his high school sweetheart Marianne with whom he shares two children. On the job, he is eager to acquire as much information as possible to counter his nervous tendencies when navigational issues arise. Peter feels most comfortable at open sea.'
			},
			experiences: [
				{id: 'OOW', value: 3}
			],
			issues: ['uncertainty']
		},
		{
			id: 3,
			type: 'OOW',
			cardType: 'OOW',
			name: {
				en: 'Louise'
			},
			gender: 'f',
			age: 42,
			title: titles.OOW,
			description: {
				en: 'Louise has vast experience as a OOW. She always acts calmly, and there is no situation she cannot handle. The crew trusts her in pressured situations. She is currently in a prolonged process of buying a house with her boyfriend.'
			},
			experiences: [
				{id: 'OOW', value: 5}
			],
			issues: ['distraction']
		},
		{
			id: 4,
			type: 'OOW',
			cardType: 'OOW',
			name: {
				en: 'Ali'
			},
			gender: 'm',
			age: 35,
			title: titles.OOW,
			description: {
				en: 'Ali is a reliable OOW who is not afraid to set boundaries if he feels insecure. He always welcomes solicited advice from his colleagues. Ali is also in love with Pia the navigator. This strains his relationship with Michael.'
			},
			experiences: [
				{id: 'OOW', value: 3}
			],
			issues: ['introvert']
		},
		{
			id: 5,
			type: 'AC',
			cardType: 'AC',
			name: {
				en: 'John'
			},
			gender: 'm',
			age: 33,
			title: titles.AC,
			description: {
				en: 'John is a well-liked colleague and calm AC. He always has a good overview and believes that every problem has a solution. He has spent all night trying to fix an issue with the navigation system.'
			},
			experiences: [
				{id: 'AC', value: 4}
			],
			issues: ['fatigue']
		},
		{
			id: 6,
			type: 'AC',
			cardType: 'AC',
			name: {
				en: 'Sarah'
			},
			gender: 'f',
			age: 28,
			title: titles.AC,
			description: {
				en: 'Sarah is the newest addition to the AC team. She is always eager to make decisions, and she is not too shy to take the lead. Sarah is known for her temper, and rumor has it that her colleagues take turns sharing negative information with her.'
			},
			experiences: [
				{id: 'AC', value: 2},
			],
			issues: ['persistent']
		},
		{
			id: 7,
			type: 'AC',
			cardType: 'AC',
			name: {
				en: 'Martin'
			},
			gender: 'm',
			age: 48,
			title: titles.AC,
			description: {
				en: 'Martin is known as the ‘Philosopher’ amongst colleagues. He is never the one to make impulsive decisions, and he always considers every outcome and information. This sometimes means that he misses the ‘perfect timing’.'
			},
			experiences: [
				{id: 'AC', value: 4}
			],
			issues: ['cautious']
		},
		{
			id: 8,
			type: 'AC',
			cardType: 'AC',
			name: {
				en: 'Jim'
			},
			gender: 'm',
			age: 35,
			title: titles.AC,
			description: {
				en: 'Jim is the most experienced of the AC’s. His colleagues describe him as a kind and calm. He is very laid-back and usually refrains from arguing with his fellow AC’s. One time, he hid in the canteen for 3 hours to avoid a management meeting.'
			},
			experiences: [
				{id: 'AC', value: 5}
			],
			issues: ['complacent', 'submissive']
		},
		{
			id: 9,
			type: 'FDO',
			cardType: 'FDO',
			name: {
				en: 'Walther'
			},
			gender: 'm',
			age: 41,
			title: titles.FDO,
			description: {
				en: 'Walther is an experienced FDO with a good overview of the helicopter deck and his team’s operational capabilities. In his role, he has gracefully tackled an emergency landing on the ship. He could improve his skills as an operations commander.'
			},
			experiences: [
				{id: 'FDO', value: 4}
			],
			issues: ['submissive'] // was empty
		},
		{
			id: 10,
			type: 'FDO',
			cardType: 'FDO',
			name: {
				en: 'Sophie'
			},
			gender: 'f',
			age: 24,
			title: titles.FDO,
			description: {
				en: 'Sophie is an aspiring, inquisitive and young FDO who graduated top of her class. Ask her anything from the theory books, and she knows the answer. She started in the FDO position two weeks ago.'
			},
			experiences: [
				{id: 'FDO', value: 1}
			],
			issues: ['inexperienced']
		},
		{
			id: 11,
			type: 'FDO',
			cardType: 'FDO',
			name: {
				en: 'Christian'
			},
			gender: 'm',
			age: 51,
			title: titles.FDO,
			description: {
				en: 'Christian has a lot of practical experience as an FDO. He knows the team’s capacities but tends to cut corners if his ideas do not match the procedures. One time, he forgot to clear the platform before an emergency landing.'
			},
			experiences: [
				{id: 'FDO', value: 4},
			],
			issues: ['autocratic']
		},
		{
			id: 12,
			type: 'FDO',
			cardType: 'FDO',
			name: {
				en: 'Chris'
			},
			gender: 'm',
			age: 45,
			title: titles.FDO,
			description: {
				en: 'Chris is an experienced FDO who takes pride in his position. Procedures are his Bible and ‘Always plan ahead” is his motto. Chris is a skilled operations commander who prefers when things go according to his plan.'
			},
			experiences: [
				{id: 'FDO', value: 5},
			],
			issues: ['stubborn']
		},
		{
			id: 13,
			type: 'FDD',
			cardType: 'FDD',
			name: {
				en: 'Morten'
			},
			gender: 'm',
			age: 33,
			title: titles.FDD,
			description: {
				en: 'Morten is a dedicated and experienced FDD who knows his team well and often makes decisions efficiently. He works long days and has a lot of responsibility. Rumor has it that he once fell asleep in a lifeboat after a long shift.'
			},
			experiences: [
				{id: 'FDD', value: 4},
			],
			issues: ['enclosed']
		},
		{
			id: 14,
			type: 'FDD',
			cardType: 'FDD',
			name: {
				en: 'Kenn'
			},
			gender: 'm',
			age: 43,
			title: titles.FDD,
			description: {
				en: 'Kenn has more than 20 years of experience as an FDD. He knows the procedures very well but prefers his own. He perceives himself as a skilled FDD. His relationship with his superior is tense as Kenn finds him as incompetent and uncommunicative.'
			},
			experiences: [
				{id: 'FDD', value: 5},
			],
			issues: ['confident']
		},
		{
			id: 15,
			type: 'FDD',
			cardType: 'FDD',
			name: {
				en: 'Angela'
			},
			gender: 'f',
			age: 34,
			title: titles.FDD,
			description: {
				en: 'Angela is the accounting officer on the ship but has acted as an FDD on many accounts. She knows the team’s capabilities but prefers numbers over people. Her attention is devoted to ‘getting the job done’ rather than following procedures.'
			},
			experiences: [
				{id: 'FDD', value: 3},
			],
			issues: ['restless']
		},
		{
			id: 16,
			type: 'FDD',
			cardType: 'FDD',
			name: {
				en: 'Alex'
			},
			gender: 'm',
			age: 30,
			title: titles.FDD,
			description: {
				en: 'Alex is, despite his young age, an experienced FDD who knows procedures and capabilities like the back of his own hand. He is a good colleague but finds it difficult to express his opinions and decisions. When things get critical, the other crew members tend to overrule him.'
			},
			experiences: [
				{id: 'FDD', value: 4},
			],
			issues: ['submissive']
		},

		{
			id: 17,
			type: 'CREW',
			cardType: 'CREW',
			name: {
				en: 'Jim'
			},
			gender: 'm',
			age: 46,
			title: titles.CREW,
			description: {
				en: 'Jim is married and has two grown up children. He loves life at sea but is more "practical than "theoretical". He is also known for being stubborn.'
			},
			experiences: [
				{id: 'REM', value: 3},
				{id: 'SUR', value: 4},
			],
			issues: ['persistent']
		},
		{
			id: 18,
			type: 'CREW',
			cardType: 'CREW',
			name: {
				en: 'Peter'
			},
			gender: 'm',
			age: 38,
			title: titles.CREW,
			description: {
				en: 'Peter (PJ) is married to his second wife who is expecting twins shortly. He has a strong personal drive, but there is not really an area that is right up his alley.'
			},
			experiences: [
				{id: 'REM', value: 3},
				{id: 'SUR', value: 3},
			],
			issues: ['fatigue']
		},
		{
			id: 19,
			type: 'CREW',
			cardType: 'CREW',
			name: {
				en: 'Adam'
			},
			gender: 'm',
			age: 29,
			title: titles.CREW,
			description: {
				en: `Adam is a highly skilled Lashing crew. His is liked by his colleagues, but he is a restless soul who does not want to be committed. He also think teamwork is 'too much talk.' Single, social and outgoing.`
			},
			experiences: [
				{id: 'REM', value: 2},
				{id: 'SUR', value: 5},
			],
			issues: ['restless']
		},
		{
			id: 20,
			type: 'CREW',
			cardType: 'CREW',
			name: {
				en: 'Louise'
			},
			gender: 'f',
			age: 36,
			title: titles.CREW,
			description: {
				en: `Louise is highly experienced and leading the Rescuer Crew group.  She is a single mom with two children by the age of 8 (girl) and age 13 (boy). She is loyal and ambitious and rarely makes any mistakes.`
			},
			experiences: [
				{id: 'REM', value: 5},
				{id: 'SUR', value: 3},
			],
			issues: ['stress']
		},
		{
			id: 21,
			type: 'CREW',
			cardType: 'CREW',
			name: {
				en: 'Niels'
			},
			gender: 'm',
			age: 22,
			title: titles.CREW,
			description: {
				en: 'Niels is single and has been in the job for 1 years. He values the learning opportunity he has been given and always agree to work long hours. He is very intelligent and very fast to aquire new knowledge. However, he is considered to be somewhat arrogant by his colleagues.'
			},
			experiences: [
				{id: 'REM', value: 2},
				{id: 'SUR', value: 2},
			],
			issues: ['introvert']
		},
		{
			id: 22,
			type: 'CREW',
			cardType: 'CREW',
			name: {
				en: 'Poul'
			},
			gender: 'm',
			age: 24,
			title: titles.CREW,
			description: {
				en: `Poul is the newest addition to the crew. He knows what he want and is not afraid to speak up. He is also a trained paramedic.`
			},
			experiences: [
				{id: 'REM', value: 3},
				{id: 'SUR', value: 1},
			],
			issues: ['stubborn']
		},
	]
}

module.exports = {crewData};