import React from 'react';
import PropTypes from 'prop-types';
import { gamesData} from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import './crew-issues.scss';

const CrewIssues = ({languageId, gameType, type, selectedCrew}) => {
	const crewData = gamesData[gameType].crewData;

	return (
		<div className={'CrewIssues ' + type}>
			<div className="CrewIssues-title"><span>{getText(generalUiTexts.personalIssues, languageId)}</span></div>
			{selectedCrew.map((crew) => {
				if (crew.effectApplied === true) {
					let crewMemberData = crewData.availableCrew.find((c) => {return c.id === crew.id;});
					if (crewMemberData && crewMemberData.issues) {
						return crewMemberData.issues.map((issue) => {
							return (
								<div key={crew.id + '-' + issue} className="CrewIssues-issue">
									<span>{getText(crewData.issueTypes[issue], languageId)}</span>
								</div>
							);
						});
					}
				}
				return null;
			})}
		</div>
	);
};

CrewIssues.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	selectedCrew: PropTypes.array.isRequired,
};

export default CrewIssues;