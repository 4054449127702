const { gameStepsUiTexts, gamePhasesUiTexts, generalUiTexts } = require('./../ui-texts');

const gameSteps = [
	/* PHASE 1 */
	{
		id: 'welcome', 
		index: 0, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.introduction, 
		subtitle: gameStepsUiTexts.welcome, 
		infoPopup: null,
		page: 'welcome'
	},
	{
		id: 'crew-select', 
		index: 1, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.crew, 
		subtitle: gameStepsUiTexts.selectCrew, 
		infoPopup: {
			title: generalUiTexts.crewInfo,
			contentId: 'crewInfoText',
			isScenarioSpecific: false,
			autoShow: true
		},
		page: 'crew-select'
	},
	{
		id: 'crew-feedback', 
		index: 2, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.crew, 
		subtitle: gameStepsUiTexts.selectCrew, 
		infoPopup: null,
		page: 'crew-feedback'
	},
	/* PHASE 2 */
	{
		id: 'planning', 
		index: 5, 
		phase: 2,
		sectionId: 1,
		title: gamePhasesUiTexts.planning, 
		subtitle: null, 
		infoPopup: {
			title: generalUiTexts.briefing,
			contentId: '',
			isScenarioSpecific: true,
			autoShow: true,
		},
		page: 'game-board'
	},
	/* PHASE 3 */
	{
		id: 'planning-reflection',
		index: 6,
		phase: 3,
		sectionId: 1,
		title: gamePhasesUiTexts.reflection,
		subtitle: null,
		infoPopup: null,		
		page: 'reflection'
	},
	{
		id: 'planning-reflection-result',
		index: 7,
		phase: 3,
		sectionId: 2,
		title: gamePhasesUiTexts.reflection,
		subtitle: gamePhasesUiTexts.results,
		infoPopup: null,		
		backBtn: true,
		page: 'reflection-result'
	},
	/* PHASE 4 */
	{
		id: 'preparation', 
		index: 8, 
		phase: 4,
		sectionId: 1,
		title: gamePhasesUiTexts.preparation, 
		subtitle: null, 
		infoPopup: {
			title: generalUiTexts.briefing,
			contentId: '',
			isScenarioSpecific: true,
			autoShow: true
		},
		page: 'game-board'
	},
	/* PHASE 5 */
	{
		id: 'start', 
		index: 9, 
		phase: 5,
		sectionId: 1,
		title: gamePhasesUiTexts.start, 
		subtitle: null, 
		infoPopup: {
			title: generalUiTexts.briefing,
			contentId: '',
			isScenarioSpecific: true,
			autoShow: true
		},
		page: 'game-board'
	},
	/* PHASE 6 */
	{
		id: 'flight', 
		index: 10, 
		phase: 6,
		sectionId: 1,
		title: gamePhasesUiTexts.flight, 
		subtitle: null, 
		infoPopup: {
			title: generalUiTexts.briefing,
			contentId: '',
			isScenarioSpecific: true,
			autoShow: true
		},
		page: 'game-board'
	},
	/* PHASE 7 */
	{
		id: 'landing', 
		index: 11, 
		phase: 7,
		sectionId: 1,
		title: gamePhasesUiTexts.landing, 
		subtitle: null, 
		infoPopup: {
			title: generalUiTexts.briefing,
			contentId: '',
			isScenarioSpecific: true,
			autoShow: true
		},
		page: 'game-board'
	},

	/* PHASE 8 */
	{
		id: 'final-reflection',
		index: 12,
		phase: 8,
		sectionId: 1,
		title: gamePhasesUiTexts.finalReflection,
		subtitle: null,
		infoPopup: null,
		page: 'reflection'
	},
	{
		id: 'final-reflection-result',
		index: 13,
		phase: 8,
		sectionId: 2,
		title: gamePhasesUiTexts.finalReflection,
		subtitle: gamePhasesUiTexts.results,
		infoPopup: null,
		backBtn: true,
		page: 'reflection-result'
	},

	/* PHASE 9 */
	{
		id: 'exercise',
		index: 14,
		phase: 9,
		sectionId: 1,
		title: gamePhasesUiTexts.debrief,
		subtitle: null,
		infoPopup: {
			title: generalUiTexts.briefing,
			contentId: '',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'exercise'
	},
	{
		id: 'exercise-result',
		index: 15,
		phase: 9,
		sectionId: 2,
		title: gamePhasesUiTexts.debrief,
		subtitle: gamePhasesUiTexts.results,
		infoPopup: {
			title: generalUiTexts.briefing,
			contentId: '',
			isScenarioSpecific: true,
			autoShow: false
		},
		backBtn: true,
		page: 'exercise-result'
	},


	/* PHASE 10 */
	{
		id: 'final-debrief',
		index: 16,
		phase: 10,
		sectionId: 1,
		title: gamePhasesUiTexts.debrief,
		subtitle: null,
		infoPopup: null,
		page: 'reflection'
	},
	{
		id: 'final-debrief-result',
		index: 17,
		phase: 10,
		sectionId: 2,
		title: gamePhasesUiTexts.reflection,
		subtitle: gamePhasesUiTexts.results,
		infoPopup: null,
		backBtn: true,
		page: 'reflection-result'
	},
	
	/* PHASE 11 */
	{
		id: 'final-result',
		index: 18, 
		phase: 11,
		sectionId: 1,
		title: gamePhasesUiTexts.results, 
		subtitle: null, 
		infoPopup: null,
		showMedal: true,
		page: 'game-result',
	},
	/* PHASE 12 */
	{
		id: 'gameover', 
		index: 19, 
		phase: 12,
		sectionId: 1,
		title: gamePhasesUiTexts.gameover, 
		infoPopup: null,
		page: 'gameover'
	}
];

module.exports = {gameSteps};