import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { gamesData} from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import {fillMeter} from 'helpers/layout-helper';
import {getText} from 'helpers/language-helper';
import './crew-experience.scss';

const CrewExperience = ({languageId, gameType, type, selectedCrew}) => {
	const crewData = gamesData[gameType].crewData;

	return (
		<div className={'CrewExperience ' + type}>
			<div className="CrewExperience-title">
				<span>{getText(generalUiTexts.crewExperience, languageId)}</span>
			</div>
			{crewData.crewSlots.map((slot, index) => {
				let value = 0;
				if (slot.experienceId === 'pilot' || slot.experienceId === 'medical') {
					let selectedCrewMember = selectedCrew.find((crew) => {return crew.slotId === slot.id;});
					if (selectedCrewMember && selectedCrewMember.effectApplied === true) {
						let crewMemberData = crewData.availableCrew.find((c) => {
							return c.id === selectedCrewMember.id;
						});
						if (
							crewMemberData && 
							crewMemberData.experiences.some((e) => {return e.id === slot.experienceId;})
						) {
							value = crewMemberData.experiences.find((e) => {return e.id === slot.experienceId;}).value;
						}
					}
				} else {
					let divFactor = 0;
					selectedCrew.forEach((crew) => {
						if (crew.effectApplied === true) {
							let crewMemberData = crewData.availableCrew.find((c) => {return c.id === crew.id;});
							if (
								crewMemberData && 
								crewMemberData.experiences.some((e) => {return e.id === slot.experienceId;})
							) {
								value = value + 
									crewMemberData.experiences.find((e) => {return e.id === slot.experienceId;}).value;
								divFactor = divFactor + 1;
							}
						}
					});
					if (divFactor > 0) value = value / divFactor;
				}

				return (
					<div key={index} className="CrewExperience-experience">
						<div className="CrewExperience-experienceTitle">
							{getText(crewData.experienceTypes[slot.experienceId].title, languageId)}
						</div>
						<div className="CrewExperience-experienceFM">
							{fillMeter(appConfig.experienceMax, value, 'CrewExperience-value', false, 0)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

CrewExperience.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	selectedCrew: PropTypes.array.isRequired,
};

export default CrewExperience;