import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { generalUiTexts } from 'data/ui-texts';
import { ReactComponent as HospitalIcon } from 'assets/images/icon-h.svg';
import { ReactComponent as BaseIcon} from 'assets/images/icon-hq.svg';
import { gamesData } from 'data/games-data';
import { renderMarkdown } from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import './info-map-popup.scss';

const InfoMapPopup = ({isFacilitator, gameStep, game, group, updateGroup, toggleInfoPopup }) => {
	
	/* Get scenario data */
	const scenariodata = gamesData[game.type].scenarios.find((sc) => {return sc.id === game.scenario;});

	/* Get briefing texts */
	const indexOfNewestBriefing = scenariodata.briefings.findIndex((b) => {return b.gameStepId === gameStep;});
	const briefings = scenariodata.briefings.filter((_, index) => {
		return index <= indexOfNewestBriefing;
	});

	/* Default selected location */
	let defaultLocationId = scenariodata.locations[0].id;
	if (group.hospital) defaultLocationId = group.hospital;
	const [selectedLocation, setSelectedLocation] = useState(defaultLocationId);
	let selectedLocationData = scenariodata.locations.find((sc) => {return sc.id === selectedLocation;});

	/* Check if group has seen all locations */
	let allLocationsSeen = true;
	if (!isFacilitator && gameStep === 'planning') {
		allLocationsSeen = false;
		if (group.hasOwnProperty('locationsSeen') && group.locationsSeen.length > 0) {
			allLocationsSeen = true;
			scenariodata.locations.forEach((location) => {
				if (location.id === defaultLocationId) return;
				if (allLocationsSeen === false) return;
				if (group.locationsSeen.indexOf(location.id) < 0) allLocationsSeen = false;
			});
		}
	}

	/* Check if player can close popup */
	const canClosePopup = (isFacilitator || allLocationsSeen ? true : false);
	
	/* Select location */
	const handleSelectLocation = (locationId) => {
		setSelectedLocation(locationId);

		if (!isFacilitator && gameStep === 'planning' && !allLocationsSeen && updateGroup) {
			let locationsSeen = (group.hasOwnProperty('locationsSeen')
				? JSON.parse(JSON.stringify(group.locationsSeen))
				: [defaultLocationId]
			);
			if (locationsSeen.indexOf(locationId) < 0) {
				locationsSeen.push(locationId);
				updateGroup({locationsSeen: locationsSeen});
			}
		}
	};

	/* Toggle popup */
	const [showLocationInfo, setShowLocationInfo] = useState(false);
	const handleTogglePopup = () => {
		if (isFacilitator || allLocationsSeen) {
			toggleInfoPopup();
		} else {
			setShowLocationInfo(true);
			setTimeout(() => {
				setShowLocationInfo(false);
			}, 5000);
		}
	};

	/* Get the gamephse of the group */
	let mapType = 1;
	if (gamesData[game.type] && gamesData[game.type].gameSteps) {
		const gameStepData = gamesData[game.type].gameSteps.find((gs) => {return gs.id === gameStep;});
		if (gameStepData) {
			const groupGamePhase = gameStepData.phase;
			if (game.scenario === 'navy' && groupGamePhase >= 5) mapType = 2;
		}
	}
	
	return (
		<div className={'InfoMapPopup'} onClick={() => {handleTogglePopup();}}>
			<div className="InfoMapPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Header */}
				<div className="InfoMapPopup-header">
					<div className="InfoMapPopup-title">{getText(generalUiTexts.briefings, game.languageId)}</div>
					<div
						className={'InfoMapPopup-closeBtn' + (!canClosePopup ? ' inactive' : '')}
						onClick={() => {handleTogglePopup();}}
					/>
				</div>

				{/* Body */}
				<div className="InfoMapPopup-body">
					<div className="InfoMapPopup-sidebar">
						<div className="InfoMapPopup-missionInfo">
							<div className="InfoMapPopup-missionInfoContent">
								{briefings.reverse().map((b, index) => {
									
									return (
										<div key={index} className={'InfoMapPopup-briefing' 
											+ (index === 0 ? ' framed' : '')}>
											{index === 0 && <>
												<strong>
													{getText(generalUiTexts.statusUpdate, game.languageId)}
												</strong><br />
											</>}
											
											{b.text ? renderMarkdown(getText(b.text, game.languageId)) : ''}
										</div>
									);
								})}
							</div>
						</div>
						<div className="InfoMapPopup-locations">
							<div className="InfoMapPopup-locationsHeader">
								{scenariodata.locations.map((location) => {
									return (
										<div
											key={location.id}
											className={'InfoMapPopup-location ' + location.type 
												+ (selectedLocation === location.id ? ' selected' : '')}
											onClick={() => {handleSelectLocation(location.id);}}
										>
											{location.type === 'base' && <BaseIcon />}
											{location.type === 'hospital' && <HospitalIcon />}
											{' ' + getText(location.title, game.languageId)}
										</div>
									);
								})}
							</div>
							<div className="InfoMapPopup-locationsBody">
								{renderMarkdown(getText(selectedLocationData.text, game.languageId))}
							</div>
						</div>
					</div>

					{/* Map */}
					<div className={'InfoMapPopup-map ' + game.scenario + ' map-' + mapType} />

					{/* Show location info popup */}
					{showLocationInfo && 
						<div 
							onClick={() => {setShowLocationInfo(false);}} 
							className="InfoMapPopup-locationInfoPopup"
						>
							<p>{getText(generalUiTexts.locationInfoPopup, game.languageId)}</p>
						</div>
					}
				</div>
			</div>
		</div>
	);
};


InfoMapPopup.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	gameStep: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	updateGroup: PropTypes.func,
	toggleInfoPopup: PropTypes.func.isRequired
};

export default InfoMapPopup;
