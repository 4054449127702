let reflectionsData = [
	// REFLECTION #1
	{
		id: 'planning-reflection',
		gameStep: 'planning-reflection',
		resultGameStep: 'planning-reflection-result',
		title: {
			en: 'Reflection #1'
		},
		subtitle: null,
		questions: [
			{
				id: 1,
				text: {
					en: 'When selecting the crew for the mission, you discussed different behaviour and personalities and the effect it could have. <br /><br />In your work, have you experienced any situations where behaviour or personality - your own or others - affected your ability to work effectively as a crew; and how did you deal with these situations?'
				},
				answerType: 'text',
				required: true
			},
			{
				id: 2,
				text: {
					en: 'How do you prepare yourself to be on alert all the time?'
				},
				answerType: 'text',
				required: true
			},
			{
				id: 3,
				text: {
					en: 'When preparing and briefing in the beginning of the duty period, how do you react if you disgree with conclusions and how do you handle any disagreements and conflicts?'
				},
				answerType: 'text',
				required: true
			}
		]
	},
	// REFLECTION #2 (Focus on the aftermath)
	{
		id: 'final-reflection',
		gameStep: 'final-reflection',
		resultGameStep: 'final-reflection-result',
		title: {
			en: 'Reflection #2'
		},
		subtitle: null,
		showFocusPoints: true,
		numberOfFocusPointsToSelect: 3,
		introText: {
			en: `What 3 (three) focus points would you highlight, if you should perform a de-briefing of the current mission?<br /><br />
						Drag the topics up or down and create your own priority list - including a top 3 list.`
		},
		focusPoints: [
			{id: 'planning', title: {en: 'Planning / Preparation'}},
			{id: 'briefings', title: {en: 'Briefings'}},
			{id: 'scramble', title: {en: 'Scramble process'}},
			{id: 'missionInfo', title: {en: 'Pre-flight Information / in-flight updates'}},
			{id: 'communication', title: {en: 'Crew communication'}},
			{id: 'coordination', title: {en: 'Crew coordination'}},
			{id: 'teamwork', title: {en: 'Teamwork'}},
			{id: 'leadership', title: {en: 'Leadership'}},
			{id: 'decisionMaking', title: {en: 'Decision making - Group and individual'}},
			{id: 'missionSuccess', title: {en: 'Mission success'}},
			{id: 'safety', title: {en: 'Crew Safety'}},
			{id: 'compliance', title: {en: 'Compliance'}},
			{id: 'training', title: {en: 'Training'}},
			{id: 'procedures', title: {en: 'Standard Operating Procedures'}}
		],
		questions: [
			{
				id: 4,
				text: {
					en: 'Why do you think these 3 are important to highlight?'
				},
				answerType: 'text',
				required: true
			},
		]
	},
	// BEST PRACTICES
	{
		id: 'final-debrief',
		gameStep: 'final-debrief',
		resultGameStep: 'final-debrief-result',
		title: {
			en: 'Debrief'
		},
		subtitle: null,
		introText: {
			en: `The topics listed to the right represent Best Practices of the Operation.<br /><br />
			Which 2 (two) of the topics are the most important to address if you want to improve safety and efficiency in your company/organisation?`
		},
		questions: [
			{
				id: 5,
				text: {
					en: 'Why do you think these two are the most important to address?'
				},
				answerType: 'text',
				rows: 10,
				required: true
			},	
			{
				id: 6,
				text: {
					en: 'Do you have suggestions and/or input for improvement?'
				},
				answerType: 'text',
				rows: 10,
				required: true
			},	
			{
				id: 7,
				text: {
					en: 'How do understand the phrase "Mission First - Safety Always"'
				},
				answerType: 'text',
				rows: 10,
				required: true
			},	
		],
		numberOfTopicsToSelect: 2,
		topics: [
			{
				id: 'handover-procedures',
				title: {
					en: 'Handover procedures'
				}
			},
			{
				id: 'communication',
				title: {
					en: 'Communication'
				}
			},
			{
				id: 'safety',
				title: {
					en: 'Flight safety versus mission efficiency'
				}
			},
			{
				id: 'briefings',
				title: {
					en: 'Briefing / Debriefing'
				}
			},
			{
				id: 'decision-making',
				title: {
					en: 'Operational decision making'
				}
			},
			{
				id: 'non-tech-skills',
				title: {
					en: 'Non-technical Skills'
				}
			},
			{
				id: 'preperation',
				title: {
					en: 'Mission Preparation and planning'
				}
			},
			{
				id: 'risk-assessment',
				title: {
					en: 'Operational Risk Assessment'
				}
			},

		]
	}
];

export {reflectionsData};