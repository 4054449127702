import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyCkW8PwU3fbHkV6QqnFbMc6h8hgzsNbp8A',
		authDomain: 'cgl-mayday-mili-test.firebaseapp.com',
		databaseURL: 'https://cgl-mayday-mili-test.firebaseio.com',
		projectId: 'cgl-mayday-mili-test',
		storageBucket: 'cgl-mayday-mili-test.appspot.com',
		messagingSenderId: '480843940204',
		appId: '1:480843940204:web:9de1cc6a689a8ce0ffb349'
	};
}


/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyBk_-VJAAQbXg5cyUpoVM_An_NcuN0-OYg',
		authDomain: 'cgl-mayday-mili-production.firebaseapp.com',
		databaseURL: 'https://cgl-mayday-mili-production.firebaseio.com',
		projectId: 'cgl-mayday-mili-production',
		storageBucket: 'cgl-mayday-mili-production.appspot.com',
		messagingSenderId: '489974228609',
		appId: '1:489974228609:web:fbddbcefbe950451574f80'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);


export default firebase.firestore;