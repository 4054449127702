/* eslint-disable max-len */
const eventCards = [
	/* Planning */
	{
		id: 1,
		gameStep: 'planning',
		type: 'default',
		title: {
			en: 'Night Shift'
		},
		text: {
			en: 'The AC on duty only had 3 hours of sleep due to a difficult mission during the night'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'You choose to continue with this AC, as it is a routine operation'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'This could lead to bad desicions in critical situations'
					},
					effects: [
						{type: 'threat', threatId: 'fatigue'},
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: -1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'You choose new AC on the vessel, but with no experience with this type of Helicopter.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'This could lead to bad decisions in general and poor communication with the helicopter'
					},
					effects: [
						{type: 'threat', threatId: 'communication'},
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: -1}
					],
				},	
			},
		],
	},
	{
		id: 2,
		gameStep: 'planning',
		type: 'default',
		title: {
			en: 'Routing (ship)'
		},
		text: {
			en: 'You set the course towards the burning coaster.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Take the shortest route which takes you though a narrow passage with shallow water adjacent to a glacier.'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Narrow, shallow water with ice may limit the manouverability of the ship.'
					},
					effects: [
						{type: 'threat', threatId: 'ice'},
						{type: 'crewSafety', value: -1},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Take a longer route which in the beginning takes you further away from the coaster.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The longer route will take you further away from the target in the beginning.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1},
					]
				}
			},
		],
	},
	{
		id: 3,
		gameStep: 'planning',
		type: 'default',
		title: {
			en: 'Fuel (helicopter)'
		},
		text: {
			en: 'The helicopter is fuelled with 70 % of maximum endurance.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		slotId: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Go with the fuel as it is. You can refuel during the mission'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You go with fuel as it is and plan on refuel later'
					},
					effects: [
						{type: 'threat', threatId: 'fuel'},
						{type: 'missionSuccess', value: -1},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Fuel the helicopter to maximum'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'This will take extra time and the fire on the coaster may escalate.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1}
					]
				}
			},
		],
	},
	{
		id: 4,
		gameStep: 'planning',
		type: 'default',
		title: {
			en: 'Payload'
		},
		text: {
			en: `You have to choose the payload.<br />2 x 50 % of Equip., 2 x Firefighters, 1 doctor.`
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'You go immediately. Choose 3 Items now and return for the rest.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You need to return and make another flight'
					},
					effects: [
						{type: 'missionSuccess', value: -1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'You wait 20 min to launch. Choose 4 items now and return for the rest.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You need to return and make another flight.'
					},
					effects: [
						{type: 'missionSuccess',  value: -1},
					]
				}
			},
			{
				id: 'c',
				text: {
					en: 'You wait 40 min to lanch and may carry everything in one flight.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You can have everyting in one go, but the fire on the coaster could get out of control'
					},
					effects: [
						{type: 'threat', threatId: 'delay'},
						{type: 'missionSuccess', value: -1}
					]
				}
			}
		],
	},

	/* preparation */
	{
		id: 6,
		gameStep: 'preparation',
		type: 'replace',
		cardId: 6,
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'communication'}],
				cardId: 101,
			},
			{
				conditions: [{type: 'threat', threatId: 'fatigue'}],
				cardId: 101,
			},	
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Congratulation, by choosing to swap the tired AC, you avoided a critical event involving fatigue.'
		},
		roundsMax: 0,
	},
	{
		id: 7,
		gameStep: 'preparation',
		type: 'default',
		title: {
			en: 'Fueling'
		},
		text: {
			en: 'The Fueling Pressure Head is U/S'
		},
		roundsMax: 1,
		effects: [
			{type: 'endurance', value: '2:30'}
		],
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Get a new fueling pressure head from storage and replace it'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'This takes some time to fix'
					},
					effects: [
						{type: 'missionSuccess', value: -1},
						{type: 'crewSafety', value: 1},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Use gravity fueling instead'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You choose to fuel the helicopter use gravity fueling'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
					]
				}
			}
		],
	},
	{
		id: 8,
		gameStep: 'preparation',
		type: 'default',
		title: {
			en: 'Traversing'
		},
		text: {
			en: 'The tech person responsible for traversing the helicopter is missing'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for the person'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'He shows up after 10 min was in the shower. He seems a little stressed.'
					},
					effects: [
						{type: 'threat', threatId: 'stress'},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: 1},
						
						
					],
				}	
			},
			{
				id: 'b',
				text: {
					en: 'Use another but unexperienced person to do the job.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Working on a non-familiar task, it could lead to stress and un-intentional errors'
					},
					effects: [
						{type: 'threat', threatId: 'stress'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1},
						{type: 'missionSuccess', value: 1},
					]
				}
			}
		],
	},
	{
		id: 9,
		gameStep: 'preparation',
		type: 'default',
		title: {
			en: 'Doctor'
		},
		text: {
			en: 'Doc is occupied treating a crew member. Estimated time is 45 min'
		},
		slotId: 4,
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Doc finish the treatment.'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Doc can go on the second flight.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 4, optionId: 'a' }, ],	
							text: {
								en: 'Doc can go on the second flight.'
							},
							effects: [
								{type: 'missionSuccess', value: -1},
								{type: 'compliance', value: 1},
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 4, optionId: 'b' }, ],	
							text: {
								en: 'Doc can go on the second flight.'
							},
							effects: [
								{type: 'missionSuccess', value: -1},
								{type: 'compliance', value: 1},
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 4, optionId: 'c' }, ],	
							text: {
								en: 'You delayed the flight earlier to take everything on board in one flight and doc is now ready to participate.'
							},
							effects: [
								{type: 'missionSuccess', value: -1},
								{type: 'compliance', value: 1},
							],
						},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Doc handover treatment to nurse and is ready for mission immediately'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Doc is ready to go on the second flight.'
					},
					effects: [
						{type: 'compliance', value: -1},
						{type: 'missionSuccess', value: 1}
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 4, optionId: 'c' }, ],	
							text: {
								en: 'You delayed the flight earlier to take everything in one flight, so he could just as well have finished treatment.'
							},
							effects: [
								{type: 'compliance', value: -1},
								{type: 'missionSuccess', value: -1}
							],
						},
					]
				}
			}
		],
	},

	/* Start */
	{
		id: 11,
		gameStep: 'start',
		type: 'replace',
		cardId: 11,
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'stress'}],
				cardId: 102,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Congratulation, by waiting for the experienced person to perform the traversing of the helicopter, you avoided a situation where a SUR/REM injured the knee.'
		},
		roundsMax: 0,
	},
	{
		id: 12,
		gameStep: 'start',
		type: 'default',
		title: {
			en: 'External Power'
		},
		text: {
			en: 'The External Power is U/S'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Get a technician to fix EXT PWR'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The technicaian manage to get the EXT PWR working again.'
					},
					effects: [
						{type: 'missionSuccess', value: -1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Start APU on Battery power'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Even with battery power low the pilots manage to start the engine.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
					]
				}
			}
		],
	},
	{
		id: 13,
		gameStep: 'start',
		type: 'replace',
		cardId: 13,
		roundsMax: 0,
		conditionals: [
			{
				conditions: [{type: 'no-threat', threatId: 'fuel'}],
				cardId: 105,
			},
		],
		title: {
			en: 'Fuel'
		},
		text: {
			en: `Since you already chose to go with the fuel you had on board, this improves your operational range without delaying the mission by defueling the helicopter.`
		},
		
	},
	{
		id: 14,
		gameStep: 'start',
		type: 'default',
		title: {
			en: 'Deck Lock'
		},
		text: {
			en: 'The deck lock is U/S.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Go outside SHOL'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Going outside SHOL increases risk.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: 1}
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 2, optionId: 'a' }, ],	
							text: {
								en: 'Turning the ship in narrow waters with ice, increases risk.'
							},
							effects: [
								{type: 'crewSafety', value: -1}
							],
						},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Turn Ship into SHOL'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Staying within SHOL.'
					},
					effects: [
						{type: 'compliance', value: 1},
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1}
					]
				}
			}
		],
	},
	/* Flight */
	{
		id: 16,
		gameStep: 'flight',
		type: 'replace',
		cardId: 16,
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'ice'}],
				cardId: 103,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Congratulation, by selecting the longer route to avoid the ice, you avoided a critical event involving a damaged Rudder'
		},
		roundsMax: 0,
	},
	{
		id: 17,
		gameStep: 'flight',
		type: 'default',
		title: {
			en: 'Spillage'
		},
		text: {
			en: 'You discover an Oil spillage on the helicopter deck.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Start cleaning the spillage, to mitigate risk of fire.'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You clean up the oil spillage.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'compliance', value: 1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Ignore. You prioritise otherwise.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You ignore. This wil increase the risk in case of fire.'
					},
					effects: [
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1},
						{type: 'threat', threatId: 'oil-spillage'},
					]
				}
			}
		],
	},
	{
		id: 18,
		gameStep: 'flight',
		type: 'default',
		title: {
			en: 'Flight Management System'
		},
		text: {
			en: 'The pilot reports FMS "black screen and is uncertain of position'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'You prefer a VFR Low level approach'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'VFR approach initiated. You are between sunset and the end of civil twilight.'
					},
					effects: [
						{type: 'threat', threatId: 'night'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1},
					],
				}
			},
			{
				id: 'b',
				text: {
					en: 'You prefer a IFR Ship control Approach'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'IFR Ship control approach initiated.'
					},
					effects: [
						{type: 'threat', threatId: 'night'},
						{type: 'crewSafety', value: 1},
						{type: 'compliance', value: 1},
					],
				}
			},
		],
	},
	{
		id: 19,
		gameStep: 'flight',
		type: 'default',
		title: {
			en: 'Chart'
		},
		text: {
			en: 'Outdated Nautical charts. You are entering shallow waters and manoevrebility decreases..'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue with reduced speed and monitor depth closely'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Reducing speed could be critical for the landing.'
					},
					effects: [
						{type: 'crewSafety', value: -1},
						{type: 'threat', threatId: 'limited-maneuverability'},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Turn ship to avoid to aviod shallow waters'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You could end up outside SHOL.'
					},
					effects: [
						{type: 'crewSafety', value: -1},
					]
				}
			},
		],
	},

	/* Landing */
	{
		id: 21,
		gameStep: 'landing',
		type: 'replace',
		cardId: 21,
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'rem'}],
				cardId: 104,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Congratulations. Because you got a new Deck crew member in the beginning you avoided a critical event involving lack of personnel.'
		},
		roundsMax: 0,		
	},
	{
		id: 22,
		gameStep: 'landing',
		type: 'default',
		title: {
			en: 'Fire Equipment'
		},
		text: {
			en: 'Technical Officer report that the Foam valve for the  hose is stuck and does not open.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Land on Ship. Only Water available'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'With no foam available, the risk of an uncontrolled fire increases.'
					},
					effects: [
						{type: 'threat', threatId: 'water-only'},
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: -1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'land elsewhere'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Getting the helicopter away from the ship will decrease risk for the crew onboard the ship.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: 1}

					]
				}
			}
		],
	},
	{
		id: 23,
		gameStep: 'landing',
		type: 'default',
		title: {
			en: 'Fishing boat'
		},
		text: {
			en: 'OOW reports a trawler is trying to recover their trawl. The trawl is right in the course of the ship.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Turn the ship. This will take it outside SHOL'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Taking the ship out SHOL makes the landing more difficult.'
					},
					effects: [
						{type: 'missionSuccess', value: -1},
						{type: 'crewSafety', value: -1},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Continue straight ahead. Warn the trawler.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Continue on course will increase the risk of damage to the ship.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1},
					]
				}
			}
		],
	},
	{
		id: 24,
		gameStep: 'landing',
		type: 'default',
		title: {
			en: 'Final Decision'
		},
		text: {
			en: 'Based on an evaluation of the entire situation, you need to select a landing site for the Helicopter.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'You decide to attempt a landing on deck.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'With technical issues with the fire equipment, the risk of an uncontrolled fire increases.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
						{type: 'crewSafety', value: -1}
				],
				}
			},
			{
				id: 'b',
				text: {
					en: 'You decide to land the helicopter in the water close to the ship'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The helicopter is probably lost.'
					},
					effects: [
						{type: 'missionSuccess', value: -1},
						{type: 'crewSafety', value: -1}
					]
				}
			},
			{
				id: 'c',
				text: {
					en: 'You decide to attempt to reach the nearest shoreline. Distance approximately 15 Nm.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'With a helicopter on fire, an attempt to fly towards the shoreline, would increase the risk for the crew.'
					},
					effects: [
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1}
					]
				}
			}
		],
	},


	/* CRITICAL CARDS */
	{
		id: 101,
		title: {
			en: 'Fatigue'
		},
		type: 'critical',
		text: {
			en: 'During the preparation you cannot get in touch with the AC.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		// isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Send someone up to check up.'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'He was fallen asleep due to fatigue. He is replaced by another AC.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
						{type: 'crewSafety', value: 1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Wait and try to call later. He may be busy.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You may forget to call back and the communication could be compromised.'
					},
					effects: [
						{type: 'threat', threatId: 'lack-of-communication'},
						{type: 'missionSuccess', value: -1},
					],
				},
			}
		],
	},
	{
		id: 102,
		title: {
			en: 'Stress'
		},
		type: 'critical',
		text: {
			en: 'A Deck crew member injures the knee and is taken to the infirmatory.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue with only 3 Deck crew members'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'It seems ok to continue with 3 Deck crew members.'
					},
					effects: [
						{type: 'threat', threatId: 'lack-of-resources'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Another Deck crew member is available but is not rested form the night duty'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Fatigue will increase the posibilities for mistakes.'
					},
					effects: [
						{type: 'threat', threatId: 'lack-of-resources'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: 1}
					],
				},
			}
		],
	},
	{
		id: 103,
		title: {
			en: 'Ice'
		},
		type: 'critical',
		text: {
			en: 'Rudder on ship is damaged and ship is outside SHOL.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		// isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue to get closer to the vessel in distress'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You continue to get closer and hopefully to get out of narrow water.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
						{type: 'compliance', value: 1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Turn ship manually into SHOL.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'This takes approximately 15 min.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'compliance', value: 1},
						{type: 'missionSuccess', value: -1}
					],
				},
			}
		],
	},
	{
		id: 104,
		title: {
			en: 'REM'
		},
		type: 'critical',
		text: {
			en: 'You are 1 (one) Rescuer (REM) short…'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue with only 3 SUR (REM)'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You continue with 1 person short.'
					},
					effects: [
						{type: 'threat', threatId: 'stress-2'},
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: -1},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Call for a backup to help you in the situation.'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'A new REM arrives. He may be out the loop.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'compliance', value: 1}
					],
				},
			}
		],
	},
	{
		id: 105,
		type: 'critical',
		title: {
			en: 'Defuel'
		},
		text: {
			en: `You now have the posibility to defuel on the NATO frigate, which is closer to the vessel in distress.`
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Defuel the helicopter and get more payload onboard and plan refuelling on the frigate'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You defuel and load more gear.'
					},
					effects: [
						{type: 'threat', threatId: 'refueling2'},
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: 1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Stick to the original plan'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You go as you are. Defueling the helicopter takes time.'
					},
					effects: [
						{type: 'missionSuccess', value: 1}
					],
				}
			}
		],
	},
];

module.exports = {eventCards};
