/* eslint-disable max-len */
const loginUiTexts = {
	adminBtn: {
		en: 'Administrator',
		ja: '管理者（アドミニストレーター）'
	},
	login: {
		en: 'Login',
		ja: 'ログイン'
	},
	facilitator: {
		en: 'Facilitator',
		ja: 'ファシリテーター'
	},
	group: {
		en: 'Group',
		ja: 'グループ'
	},
	email: {
		en: 'e-mail',
		ja: 'グループ'
	},
	password: {
		en: 'password',
		ja: 'パスワード'
	},
	gameCode: {
		en: 'game code',
		ja: 'ゲームコード'
	},
	forgotPassword: {
		en: 'Forgot your password?',
		ja: 'パスワードをお忘れですか？'
	},
	backToLogin: {
		en: 'Go back to login',
		ja: 'ログインに戻る'
	},
	resetPassword: {
		en: 'Enter your e-mail to reset your password:',
		ja: 'パスワードをリセットするためＥメールを入力してください:'
	},
	reset: {
		en: 'Reset',
		ja: 'リセット'
	},
	cancel: {
		en: 'Cancel',
		ja: 'キャンセル'
	},
	passwordResetFeedback: {
		en: 'A link to reset your password has been sent to your e-mail.',
		ja: 'パスワードをリセットするためのリンクがＥメールに送信されました。'
	},
	unknownError: {
		en: 'Error: unknown error',
		ja: 'エラー: 不明なエラー'
	},
	someFieldsMissing: {
		en: 'Error: Please fill out all fields',
		ja: 'エラー: 全てのフィールドに入力してください'
	},
	gameNotFound: {
		en: 'Error: Game not found',
		ja: 'エラー: ゲームが見つかりません'
	},
	invalidEmail: {
		en: 'Error: Invalid e-mail',
		ja: 'エラー: 無効なEメール'
	},
	userNotFound: {
		en: 'Error: User not found',
		ja: 'エラー: ユーザーが見つかりません'
	},
	invalidPassword: {
		en: 'Error: Wrong password',
		ja: 'エラー: 誤ったパスワード'
	},
	notAvailable: {
		en: 'Error: This game is no longer available',
		ja: 'エラー: このゲームは現在利用できません'
	},
	couldNotFindCompany: {
		en: 'Error: Could not find company',
		ja: 'エラー: カンパニーが見つかりません'
	},
	notAuthorized: {
		en: 'Error: You are not authorized as admin',
		ja: 'エラー: 管理者権限がありません'
	},
	error: {
		en: 'Error',
		ja: 'エラー'
	}
};


const settingsUiTexts = {
	overview: {
		en: 'Overview',
		ja: '概要'
	},
	createGame: {
		en: 'Create game',
		ja: 'ゲームの作成'
	},
	myGames: {
		en: 'My games',
		ja: 'マイ・ゲーム'
	},
	allGames: {
		en: 'All games',
		ja: '全てのゲーム'
	},
	play: {
		en: 'Play',
		ja: 'プレイ'
	},
	active: {
		en: 'Active',
		ja: 'アクティブ'
	},
	edit: {
		en: 'Edit',
		ja: '編集'
	},
	gameCode: {
		en: 'Game code',
		ja: 'ゲームコード'
	},
	deleteGamePopup: {
		title: {
			en: 'Delete game',
			ja: 'ゲームの削除'
		},
		areYouSure: {
			en: 'Are you sure you want to delete the game %game%',
			ja: 'ゲーム %game% を削除してもよいですか'
		},
		delete: {
			en: 'Delete',
			ja: '削除'
		},
		cancel: {
			en: 'Cancel',
			ja: 'キャンセル'
		},
	},
	home: {
		en: 'Home',
		ja: 'ホーム'
	},
	editGame: {
		en: 'Edit game',
		ja: 'ゲームの編集'
	},
	createdBy: {
		en: 'Created by',
		ja: '作成'
	},
	company: {
		en: 'Company',
		ja: 'カンパニー'
	},
	gameTitle: {
		en: 'Game session title',
		ja: 'ゲームセッション・タイトル'
	},
	gameTitlePlaceholder: {
		en: 'enter title',
		ja: 'タイトルを入力してください'
	},
	gameDate: {
		en: 'Date for game session',
		ja: 'ゲームセッションの日付'
	},
	scenario: {
		en: 'Scenario type',
		ja: 'シナリオタイプ'
	},
	decisionTool: {
		en: 'Decision making tool',
		ja: '意思決定ツール'
	},
	communicationTool: {
		en: 'Communication tool',
		ja: 'コミュニケーションツール'
	},
	exerciseTool: {
		en: 'Exercise tool',
		ja: '演習ツール'
	},
	language: {
		en: 'Language',
		ja: '言語'
	},
	create: {
		en: 'Create',
		ja: '作成'
	},
	save: {
		en: 'Save',
		ja: '保存'
	},
	numberOfGroups: {
		en: 'Number of groups',
		ja: 'グループの数'
	},
	group: {
		en: 'Group',
		ja: 'グループ'
	},
	groups: {
		en: 'Groups',
		ja: '(複数)グループ'
	},
	startTypingPlayerName: {
		en: 'Start typing player name',
		ja: 'プレイヤーの名前をタイプしてください'
	},
	noMatchingNames: {
		en: 'No matching names',
		ja: '名前が一致しません'
	},
	allNamesAdded: {
		en: 'All names are added',
		ja: '全ての名前が追加されました'
	},
	missingGameTitle: {
		en: 'You must enter a game title',
		ja: 'ゲームタイトルを入力してくださいa'
	},
	gameTitleMaxLength: {
		en: 'The game title must be max %chars% characters long',
		ja: 'ゲームタイトルは最大 %chars% 文字の長さです'
	},
	saveBeforePrint: {
		en: 'You need to create the game before you can print.',
		ja: 'プリントの前にゲームを作成する必要があります'
	},
	gameUrl: {
		en: 'Game URL: ',
		ja: 'ゲーム URL:'
	},
	scheduledStart: {
		en: 'Scheduled start date',
		ja: 'スケジュールされた開始日'
	},
	createdDate: {
		en: 'Created date',
		ja: '作成日'
	},
	currentGamePhase: {
		en: 'Current game phase',
		ja: '現在のゲームフェーズ'
	},
	currentGameStep: {
		en: 'Current game step',
		ja: '現在のゲームステップ'
	},
	goToNextGamePhase: {
		en: 'Open next phase',
		ja: '次のフェーズを開く'
	},
	openNextGamePhase: {
		en: 'Open',
		ja: '開く'
	},
	finishGame: {
		en: 'Finish game',
		ja: 'ゲームの終了'
	},
	nextPhaseWarningPopup: {
		title: {
			en: 'Warning',
			ja: '警告'
		},
		text: {
			en: 'Some groups have not completed the current phase. Do you still want to open the next phase?',
			ja: '現在のフェーズを終了していないグループがあります。それでも次のフェーズを開きますか？'
		},
		confirm: {
			en: 'Confirm',
			ja: '確認'
		},
		cancel: {
			en: 'Cancel',
			ja: 'キャンセル'
		}
	},
	status: {
		en: 'Status',
		ja: 'ステータス'
	},
	noStatusPopup: {
		title: {
			en: 'Status',
			ja: 'ステータス'
		},
		text: {
			en: 'The status shows an overview of group responses on debriefs. It is only active when all groups have completed the debrief. In rounds without debriefs, there is no status overview.',
			ja: 'ステータスは、デブリーフィングに関するグループの回答の概要を表示します。このステータスは、すべてのグループがデブリーフィングを完了したときのみアクティブになります。デブリーフィングがないラウンドでは、ステータスの概要は表示されません。'
		}
	},
	pause: {
		en: 'Pause',
		ja: '一時停止'
	},
	unpause: {
		en: 'Unpause',
		ja: '一時停止の終了'
	},
};


const generalUiTexts = {
	welcome: {
		en: 'Welcome',
		ja: 'ようこそ'
	},
	group: {
		en: 'Group',
		ja: 'グループ'
	},
	start: {
		en: 'Start',
		ja: '開始'
	},
	continue: {
		en: 'Continue',
		ja: '続行'
	},
	next: {
		en: 'Next',
		ja: '次へ'
	},
	ok: {
		en: 'OK',
		ja: 'OK'
	},
	confirm: {
		en: 'Confirm',
		ja: '確認'
	},
	submit: {
		en: 'Submit',
		ja: '送信'
	},
	cancel: {
		en: 'Cancel',
		ja: 'キャンセル'
	},
	paused: {
		en: 'Game paused',
		ja: 'ゲームの一時停止'
	},
	waiting: {
		en: 'Waiting for facilitator...',
		ja: 'ファシリテーターを待っています...'
	},

	crewInfo: {
		en: 'Crew Information',
		ja: 'クルーの情報'
	},
	flightInfo: {
		en: 'Flight Information',
		ja: 'フライトの情報'
	},
	preflightBriefing: {
		en: 'Preflight briefing',
		ja: '飛行前のブリーフィング(打ち合わせ）'
	},
	briefing: {
		en: 'Briefing',
		ja: 'ブリーフィング（打ち合わせ）'
	},
	briefings: {
		en: 'Briefings',
		ja: 'ブリーフィング'
	},
	statusUpdate: {
		en: 'Status update',
		ja: 'ステータスの更新'
	},
	locationInfoPopup: {
		en: 'You have to check the weather conditions at each location before you can close this popup.',
		ja: 'このポップアップを閉じるには、各地点の天候を確認する必要があります。'
	},
	
	title: {
		en: 'Title',
		ja: 'タイトル'
	},
	age: {
		en: 'Age',
		ja: '年齢'
	},
	crewExperience: {
		en: 'Crew experience',
		ja: 'クルーの経験'
	},
	personalIssues: {
		en: 'Personal issues',
		ja: '個人の問題点'
	},
	certifyingStaffOnly: {
		en: 'For CERTIFYING STAFF only',
		ja: 'CERTIFYING STAFFのみ'
	},
	heliCrewCardBackText: {
		en: 'Here you see %names% degree of experience and personal issues',
		ja: 'ここでは、%names%の経験度や個人的な問題点を見ることができます。'
	},
	crewFeedbackInstructionsTitle: {
		en: 'Selected crew',
		ja: '選択されたクルー'
	},
	crewFeedbackInstructionsText: {
		en: 'Flip the cards to see what effects your crew selection has. You might get RISK and / or DIRTY DOZEN.<br /><br />Once you have turned all crew cards you are ready to continue.',
		ja: 'カードをめくって、自分の選んだクルーがどのような効果を持つかを確認します。RISK / もしくはDIRTY DOZENを知ることができます。<br /><br />すべてのクルーカードをひっくり返すと、続行する準備が整います。'
	},
	crewFeedbackInstructionsTextHeli: {
		en: 'Flip the cards to see your crew\'s experience and personal issues. The technical crew has experience in both paramedic and system operation, but benefits primarily from the role you have assigned on this mission.<br /><br />Once you have turned all crew cards you are ready to continue.',
		ja: 'カードをめくって、クルー達の経験や個人的な問題点を確認します。テクニカルクルーは、救急措置とシステム運用の両方の経験を持っていますが、このミッションであなたが割り当てた役割を主に得ています。<br /><br />すべてのクルーカードをひっくり返すと、続行する準備が整います。'
	},

	reflection: {
		textPlaceholder: {
			en: 'Type your answer here',
			ja: '回答をここに記入してください'
		},
		notAtAll: {
			en: 'Not at all',
			ja: 'まったくない'
		},
		extremely: {
			en: 'Extremely',
			ja: '非常に'
		},
		question: {
			en: 'Question',
			ja: '疑義'
		},
		unknownReflection: {
			en: 'Unknown reflection',
			ja: '不明な振り返り'
		},
		tool: {
			en: 'Tool',
			ja: 'ツール'
		},
		incident: {
			en: 'Incident',
			ja: 'インシデント(出来事）'
		},
		topics: {
			en: 'Topics',
			ja: 'トピックス'
		},
	},

	dirtyDozen: {
		en: 'Dirty Dozens',
		ja: 'ダーティ・ダズン(Dirty Dozens)'
	},
	dirtyDozen2: {
		en: 'Dirty Dozen',
		ja: 'ダーティ・ダズン(Dirty Dozens)'
	},
	inconvenience: {
		en: 'Inconvenience',
		ja: '不便'
	},
	risk: {
		en: 'Risk',
		ja: 'リスク'
	},
	doubleRisk: {
		en: 'Risk (x2)',
		ja: 'リスク (x2)'
	},
	extraRisk: {
		en: 'Extra risk',
		ja: '非常に大きなリスク'
	},
	extraTime: {
		en: 'Extra time',
		ja: '余計な時間'
	},
	extraCost: {
		en: 'Extra cost',
		ja: '余計なコスト'
	},
	crewSafety: {
		en: 'Crew Safety',
		ja: 'クルーの安全'
	},
	missionSuccess: {
		en: 'Mission Success',
		ja: 'ミッションの成功'
	},
	compliance: {
		en: 'Compliance',
		ja: 'コンプライアンス（法令遵守）'
	},
	resources: {
		en: 'Resources',
		ja: 'リソース'
	},
	threats: {
		en: 'Threats',
		ja: 'スレット（脅威）'
	},

	crewPopupTitle: {
		en: 'Crew',
		ja: 'クルー'
	},
	groupsPopupTitle: {
		en: 'Groups',
		ja: 'グループ'
	},

	defaultThisTurn: {
		en: 'Default THIS turn',
		ja: 'デフォルト(規定) 現在のターン'
	},
	defaultNextTurn: {
		en: 'Default next turn',
		ja: 'デフォルト(規定) 次のターン'
	},
	noDefaultOption: {
		en: 'NO DEFAULT, you must choose',
		ja: 'デフォルトなし、選択してください'
	},
	free: {
		en: 'Free',
		ja: 'フリー'
	},
	movesToNextRound: {
		en: 'Moves to next turn',
		ja: '次のターンへ移動する'
	},
	callFacilitator: {
		en: 'CALL FACILITATOR',
		ja: 'ファシリテーターを呼ぶ'
	},

	warning: {
		en: 'Warning',
		ja: '警告'
	},
	requiredOptionsWarning: {
		en: 'One or more cards require that you select an option.',
		ja: '１つかそれ以上のカードでオプションを選ぶ必要があります'
	},
	resourceWarning: {
		en: 'You have not placed all your resources, are you sure you want to confirm?',
		ja: 'すべてのリソースを配置していませんが、本当に確認しますか？'
	},
	turnCard: {
		en: 'Turn card',
		ja: 'カードをめくる'
	},
	nextTurn: {
		en: 'Next turn',
		ja: '次のターン'
	},
	youGet: {
		en: 'You get',
		ja: '入手・了解'
	},
	noDirtyDozen: {
		en: 'You get no Dirty Dozen',
		ja: 'ダーティ・ダズンを手に入れていません'
	},
	newDirtyDozen: {
		en: 'received a new Dirty Dozen',
		ja: 'は新しいダーティ・ダズンを受け取りました'
	},
	extraDirtyDozen: {
		en: 'already has %dd%. Proceed with caution!',
		ja: 'は既に%dd%を持っています。注意して続行してください！'
	},
	multiDirtyDozen: {
		en: 'already has double %dd%!',
		ja: 'はすでに２つ%dd%を持っています！'
	},

	taskExtraTime: {
		en: 'This task took extra time',
		ja: 'このタスクは更なる時間がかかりました'
	},
	taskExtraRisk: {
		en: 'This task increased the risk',
		ja: 'このタスクはリスクを増やしました'
	},
	taskExtraCost: {
		en: 'This task increased the cost',
		ja: 'このタスクはコストを増やしました'
	},
	is: {
		en: 'is',
		ja: 'は'
	},
	has: {
		en: 'has',
		ja: 'は'
	},


	flipCards: {
		en: 'Flip the cards and gain the benefits',
		ja: 'カードをめくり、ベネフィットを得てください'
	},

	extra: {
		en: 'Extra',
		ja: 'エクストラ（追加・余分）'
	},
	youChose: {
		en: 'You chose',
		ja: '選びました'
	},

	results: {
		en: 'Results',
		ja: '結果'
	},
	total: {
		en: 'Total',
		ja: 'トータル'
	},
};

const gamePhasesUiTexts = {
	crewSelection: {
		en: 'Crew Selection',
		ja: 'クルー選択'
	},
	staffSelection: {
		en: 'Staff selection',
		ja: 'スタッフ選択'
	},
	onTheGround: {
		en: 'On the ground',
		ja: '地上'
	},
	airborne: {
		en: 'Airborne',
		ja: '飛行中'
	},
	decisionMaking: {
		en: 'Decision making',
		ja: '意思決定'
	},
	communication: {
		en: 'Communication',
		ja: 'コミュニケーション'
	},
	planning: {
		en: 'Planning',
		ja: ''
	},
	preparation: {
		en: 'Preparation',
		ja: ''
	},
	start: {
		en: 'Start',
		ja: ''
	},
	flight: {
		en: 'Flight',
		ja: ''
	},
	landing: {
		en: 'Landing',
		ja: ''
	},
	day1: {
		en: 'Day 1',
		ja: 'Day 1'
	},
	day2: {
		en: 'Day 2',
		ja: 'Day 2'
	},
	day3: {
		en: 'Day 3',
		ja: 'Day 3'
	},
	day4: {
		en: 'Day 4',
		ja: 'Day 4'
	},
	finalReflection: {
		en: 'Final reflection',
		ja: '最終の振り返り'
	},
	finalResults: {
		en: 'Final results',
		ja: '最終の結果'
	},
	totalScore: {
		en: 'Total score',
		ja: 'トータルスコア'
	},
	gameover: {
		en: 'Gameover',
		ja: 'ゲームオーバー'
	},
	
	game: {
		en: 'Game',
		ja: 'ゲーム'
	},
	reflection: {
		en: 'Reflection',
		ja: '振り返り'
	},
	debrief: {
		en: 'Debrief',
		ja: '終了後打ち合わせ（デブリーフ）'
	},
	missionDebriefing: {
		en: 'Mission De-briefing',
		ja: 'ミッションのデブリーフィング'
	},
	bestPractices: {
		en: 'Best Practices',
		ja: 'ベスト・プラクティス'
	},
	results: {
		en: 'Results',
		ja: '結果'
	},	

	firstPhase: {
		en: 'First phase',
		ja: '最初のフェーズ'
	},
	thisPhase: {
		en: 'This phase',
		ja: 'このフェーズ'
	},
	nextPhase: {
		en: 'Next phase',
		ja: '次のフェーズ'
	},
	open: {
		en: 'Open',
		ja: '開く'
	},
	endGame: {
		en: 'End game',
		ja: 'ゲーム終了'
	}
};

const gameStepsUiTexts = {
	mayday: {
		en: 'MAYDAY',
		ja: 'メーデー'
	},
	introduction: {
		en: 'Introduction',
		ja: 'イントロダクション'
	},
	welcome: {
		en: 'Welcome',
		ja: 'ようこそ'
	},
	crew: {
		en: 'Crew',
		ja: 'クルー'
	},
	selectCrew: {
		en: 'Select crew',
		ja: 'クルーの選択'
	},
	selectStaff: {
		en: 'Select staff',
		ja: 'スタッフの選択'
	},
	crewSelection: {
		en: 'Crew Selection',
		ja: 'クルー選択'
	},
	selectedStaff: {
		en: 'Selected staff', 
		ja: '選択したクルー'
	},
	staffReflection: {
		en: 'Staff reflection',
		ja: 'スタッフ反映'
	},
	reflection: {
		en: 'Reflection',
		ja: '振り返り・回想'
	},
	results: {
		en: 'Results',
		ja: '結果'
	},
	planning: {
		en: 'Planning',
		ja: 'チエックイン（業務開始時)'
	},
	preflight: {
		en: 'Pre-flight',
		ja: '飛行前'
	},
	boarding: {
		en: 'Boarding',
		ja: '飛行機への搭乗'
	},
	cruise: {
		en: 'Cruise',
		ja: '巡航飛行'
	},
	pilotIncapacitation: {
		en: 'Pilot Incapacitation',
		ja: 'パイロットの操縦能力喪失'
	},
	turbulence: {
		en: 'Turbulence',
		ja: 'タービュランス'
	},
	fire: {
		en: 'Fire',
		ja: 'Fire' // TODO
	},
	warnings: {
		en: 'Warnings',
		ja: '警告・警報'
	},
	input: {
		en: 'Input',
		ja: '入力'
	},
	flights: {
		en: 'Flights',
		ja: 'フライト'
	},
	firstDebrief: {
		en: '1st DEBRIEF',
		ja: '１回目のデブリーフィング'
	},
	secondDebrief: {
		en: '2nd DEBRIEF',
		ja: '２回目のデブリーフィング'
	},
	thirdDebrief: {
		en: '3rd DEBRIEF',
		ja: '３回目のデブリーフィング'
	}
};

const adminUiTexts = {
	admin: 'Admin',
	company: 'Company',
	companies: 'Companies',
	facilitator: 'Facilitator',
	facilitators: 'Facilitators',
	admins: 'Admins',
	newCompany: 'New company',
	createNewCompany: 'Create new company',
	createNewFacilitator: 'Create new facilitator',
	id: 'Id',
	name: 'Name',
	email: 'E-mail',
	date: 'Date',
	created: 'Created',
	lastLogin: 'Last login',
	games: 'Games',
	game: 'Game',
	group: 'Group',
	status: 'Status',
	sendInvite: 'Send invitation / reset password',
	notInvited: 'Not invited',
	enabled: 'Enabled',
	disabled: 'Disabled',
	sendEmail: 'Send e-mail',
	scenario: 'Scenario',
	currentPhase: 'Current phase',
	players: 'Players',
	groups: 'Groups',
	saveChanges: 'Save changes',
	disableAccount: 'Disable account',
	activateAccount: 'Activate account',
	deleteAccount: 'Delete account',
	deleteCompany: 'Delete company',
	delete: 'Delete',
	disable: 'Disable',
	cancel: 'Cancel',
	ok: 'Ok',
	createFacilitator: 'Create new facilitator',
	create: 'Create',
	companyName: 'Company name',
	importPlayers: 'Import players',
	deleteAllPlayers: 'Delete all players',
	selectedCrew: 'Selected crew',
	reflectionResults: 'Reflection',
	onTheGround: 'On the ground',
	airborne: 'Airborne',
	decisionMaking: 'Decision making',
	communicationTool: 'Communication tool',
	exerciseTool: 'Exercise tool',
	finalReflection: 'Final reflection',
	finalResults: 'Final results',
	confirmDisableFacilitatorPopup: {
		title: 'Disable facilitator',
		text: 'Are you sure you want to disable the account of %name%?<br /><br />They will not be able to log in until you enable it again. Their games will not be deleted.',
		text2: 'You cannot disable your own account.',
	},
	confirmDeleteFacilitatorPopup: {
		title: 'Delete facilitator',
		text: 'Are you sure you want to delete the account of %name%? This will also delete all their games.',
		text2: 'You cannot delete your own account.',
	},
	confirmDeleteCompanyPopup: {
		title: 'Delete company',
		text: 'Are you sure you want to delete the company \'%company%\'?<br /><br />By deleting the company all games, game data and facilitators, belonging to the company will be removed. If needed you can download gamedata before deleting.',
	},
	errorMessages: {
		notAllContentLoaded: 'Error: Could not load all content. Check your internet connection and reload page.',
		noFileSelected: 'Error: No file selected',
		missingProperties: 'Error: Wrong format, could not find "id" and/or "name"',
		formatError: 'Error: Format error',
		missingImports: 'Warning: Not all players could not be imported due to formatting issues',
		unknownError: 'Error: Unknown error',
		noNewData: 'Warning: No new data added'
	},
	importPlayersWarning: 'You need to save the company before you can import players.',
	deleted: 'deleted',
	yes: 'yes',
	no: 'no'
};

module.exports = {
	loginUiTexts,
	settingsUiTexts,
	generalUiTexts,
	gamePhasesUiTexts,
	gameStepsUiTexts,
	adminUiTexts,
};
