import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import { languagesData } from 'data/languages-data';
import apiHelper from 'helpers/api-helper';
import { formatDate } from 'helpers/date-helper';
import Loading from 'components/loading/loading';
import SettingsController from 'components/settings/settings-controller';
import OverviewController from 'components/overview/overview-controller';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isResetting: false,
			showAllGames: false,
			page: 'settings',
			userData: null,
			companiesData: [],
			games: [],
			groups: [],
			users: [],
			gameId: null,
		};
		this.unsubscribeUser = null;
		this.unsubscribeGames = null;
		this.unsubscribeGroups = null;
		this.unsubscribeUsers = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get user and company data, subscribe to games */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				let promises = [this.getCompanyData(), this.subscribeToGames()];
				if (this.state.userData.isAdmin) promises.push(this.subscribeToUsers());
				Promise.all(promises).then((responses) => {
					if (responses.some((response) => {return response.status === 'error';})) {
						console.error('Error loading / company data / games / users');
					}
					this.setState({isLoading: false});
				});
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from games / groups */
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();
	};

	/**
	 * Get company data
	 */
	getCompanyData = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection('companies').get().then((docs) => {
				let companiesData = [];
				docs.forEach((doc) => {
					if (companiesData.length > 0 && !(this.state.userData.isAdmin === true)) return;
					if (this.state.userData.isAdmin === true || this.state.userData.companyId === doc.id) {
						companiesData.push({...doc.data(), id: doc.id});
					}
				});
				if (companiesData.length > 0) {
					this.setState({companiesData}, () => {resolve({status: 'success'});});
				} else {
					resolve({status: 'error'});
				}
			}).catch((error) => {
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
 * Subscribe to user (facilitator) data
 */
	subscribeToUser = () => {
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection('users').doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
				/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Set facilitator language if not already set */
					if (
						!userData.languageId ||
					!languagesData.some((ld) => {return ld.id === userData.languageId;})
					) {
						userData.languageId = this.props.languageId;
						this.updateUser({languageId: this.props.languageId});
					}

					/* Update last login date */
					this.updateUser({lastLogin: formatDate(Math.floor(Date.now() / 1000))});

					/* Update state */
					this.setState({userData}, () => {resolve({ status: 'success' });});
				} else {
					this.props.handleLogout();
					resolve({ status: 'error'});
				}
			},
			(error) => {
				console.error('could not get group: ', error);
				this.props.handleLogout();
				resolve({ status: 'error'});
			}
			);
		});
	};
	

	/**
	 * Called by the teacher to subscribe to all their games.
	 * @param {string} userId
	 */
	subscribeToGames = () => {
		let userId = this.props.userId;
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			let query = db.collection(appConfig.gamesDbName).where('type', '==', this.props.gameType);
			if (!this.state.userData.isAdmin === true) {
				query = query.where('facilitatorId', '==', userId);
			}
			
			this.unsubscribeGames = query.onSnapshot((querySnapshot) => {
				let games = [];
				if (!querySnapshot.empty) {
					games = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						if (
							!data.languageId || 
							!languagesData.some((ld) => {return ld.id === data.languageId;})
						) data.languageId = appConfig.defaultLanguage;
						return data;
					});
				}
				this.setState({ games: games}, () => {resolve({status: 'success'});});
			},
			(error) => {
				console.error('could not get games: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Subscribe to the groups of a game
	 * @param {string} gameId
	 */
	subscribeToGroups = (gameId) => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		let db = firebase.firestore();
		return new Promise((resolve, reject) => {
			this.unsubscribeGroups = db
				.collection(appConfig.groupsDbName)
				.where('gameId', '==', gameId)
				.onSnapshot(
					(querySnapshot) => {
						let groups = [];
						querySnapshot.forEach((doc) => {
							let data = doc.data();
							data.id = doc.id;
							groups.push(data);
						});
						this.setState({ groups: groups }, () => {
							resolve();
						});
					},
					(error) => {
						console.error('could not get groups: ', error);
						reject(error);
					}
				);
		});
	};

	/**
	 * Subscribe to users (admin only)
	 */
	subscribeToUsers = () => {
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();
		let db = firebase.firestore();
		return new Promise((resolve, reject) => {
			this.unsubscribeGroups = db.collection('users').onSnapshot(
				(querySnapshot) => {
					let users = [];
					querySnapshot.forEach((doc) => {users.push({id: doc.id, ...doc.data()});});
					this.setState({users: users}, () => {resolve({status: 'success'});});
				},
				(error) => {
					console.error('could not get groups: ', error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Update user (facilitator) data
	 * @param {object} updates 
	 * @returns 
	 */
	updateUser = (updates) => {
		const userId = this.props.userId;
		const db = firebase.firestore();
		const userRef = db.collection('users').doc(userId);
		return userRef.update(updates);
	};

	/**
	 * Hide / show games by other facilitators (admin only)
	 */
	toggleShowAllGames = (showAllGames) => {
		this.setState({showAllGames});
	};

	/**
	 * Create new game
	 * @param {string} gameTitle
	 * @param {string} gameDate
	 * @param {string} gameScenario
	 * @param {string} gameDecisionTool
	 * @param {string} gameCommunicationTool
	 * @param {array} gameGroups
	 */
	createGame = (
		companyId,
		gameId, 
		gameTitle, 
		gameDate, 
		gameScenario, 
		gameDecisionTool, 
		gameCommunicationTool, 
		gameExerciseTool,
		languageId,
		gameGroups
	) => {
		return new Promise((resolve, reject) => {
			apiHelper('facilitator/create-game', {
				userId: this.props.userId,
				companyId,
				gameId,
				gameTitle,
				gameDate,
				gameType: this.props.gameType,
				gameScenario,
				gameDecisionTool,
				gameCommunicationTool,
				gameExerciseTool,
				languageId,
				gameGroups,
			}).then(
				(response) => {
					resolve(response);
				},
				(rejection) => {
					reject(rejection);
				}
			);
		});
	};

	// /**
	//  * Edit game
	//  * @param {string} gameId
	//  * @param {string} gameTitle
	//  * @param {string} gameDate
	//  * @param {string} gameScenario
	//  * @param {string} gameDecisionTool
	//  * @param {string} gameCommunicationTool
	//  * @param {array} gameGroups
	//  */
	// editGame = (
	// 	gameId, 
	// 	gameTitle, 
	// 	gameDate, 
	// 	gameScenario, 
	// 	gameDecisionTool, 
	// 	gameCommunicationTool, 
	// 	languageId, 
	// 	gameGroups
	// ) => {
	// 	return new Promise((resolve, reject) => {
	// 		apiHelper('facilitator/edit-game', {
	// 			gameId,
	// 			gameTitle,
	// 			gameDate,
	// 			gameScenario,
	// 			gameDecisionTool,
	// 			gameCommunicationTool,
	// 			languageId,
	// 			gameGroups,
	// 		}).then(
	// 			(response) => {
	// 				resolve(response);
	// 			},
	// 			(rejection) => {
	// 				reject(rejection);
	// 			}
	// 		);
	// 	});
	// };

	/**
	 * Delete game
	 * @param {string} gameId
	 */
	deleteGame = (gameId) => {
		return new Promise((resolve, reject) => {
			apiHelper('facilitator/delete-game', {
				userId: this.props.userId,
				gameId: gameId,
			}).then(
				(response) => {
					resolve(response);
				},
				(rejection) => {
					reject(rejection);
				}
			);
		});
	};

	/**
	 * Reset game (dev only feature)
	 * @param {string} gameId
	 */
	handleResetGame = (gameId) => {
		this.setState({ isResetting: true }, () => {
			apiHelper('facilitator/reset-game', { gameId: gameId }).then(
				() => {
					this.setState({ isResetting: false });
				},
				(rejection) => {
					this.setState({ isResetting: false });
					console.error('rejection: ', rejection);
				}
			);
		});
	};

	/**
	 * Go to game
	 * @param {string} gameId
	 */
	handleGoToGame = (gameId) => {
		/* Game already selectd */
		if (gameId === this.state.gameId) {
			this.setState({ page: 'overview' });
			return;
		}

		/* Select game and subscribe to its groups */
		this.setState(
			{
				gameId: gameId,
				page: 'overview',
				groups: [],
			},
			() => {
				this.subscribeToGroups(gameId);
			}
		);
	};

	/**
	 * Go to game page
	 * @param {string} page
	 */
	handleGoToPage = (page) => {
		this.setState({ page: page });
	};

	/**
	 * Update game
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGame = (updates, id) => {
		let gameId = id ? id : this.state.gameId;
		let db = firebase.firestore();
		let gameRef = db.collection(appConfig.gamesDbName).doc(gameId);
		return gameRef.update(updates);
	};

	/**
	 * Unlock game step
	 * @param {string} stepId
	 */
	unlockGameStep = (stepId) => {
		this.updateGame({ [stepId]: true });
	};

	/**
	 * Go to game phase
	 * @param {number} gamePhase
	 */
	goToGamePhase = (gamePhase) => {
		this.updateGame({ gamePhase: gamePhase });
	};

	/**
	 * Pause Game
	 * @param {boolean} isPaused
	 */
	pauseGame = (isPaused) => {
		this.updateGame({ isPaused: isPaused });
	};

	/**
	 * Init Game
	 */
	initGame = (gameId) => {
		this.updateGame({ isStarted: true }, gameId);
	};


	/**
	 * Render component
	 */
	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}

		switch (this.state.page) {
		case 'settings':
			return (
				<SettingsController
					showAllGames={this.state.showAllGames}
					userData={this.state.userData}
					companiesData={this.state.companiesData}
					gameType={this.props.gameType}
					games={this.state.games}
					users={this.state.users}
					updateUser={this.updateUser}
					toggleShowAllGames={this.toggleShowAllGames}
					handleGoToGame={this.handleGoToGame}
					createGame={this.createGame}
					initGame={this.initGame}
					// editGame={this.editGame}
					deleteGame={this.deleteGame}
					handleResetGame={this.handleResetGame}
					handleLogout={this.props.handleLogout}
				/>
			);
		case 'overview':
			let game = null;
			if (this.state.games.some((game) => {return game.id === this.state.gameId;})) {
				game = this.state.games.find((game) => {return game.id === this.state.gameId;});
			}
			let companyData = (this.state.companiesData.length > 0 ? this.state.companiesData[0] : null);
			if (game && game.companyId && this.state.companiesData.some((c) => {return c.id === game.companyId;})) {
				companyData = this.state.companiesData.find((c) => {return c.id === game.companyId;});
			}
			return (
				<OverviewController
					isFullscreen={this.props.isFullscreen}
					userData={this.state.userData}
					game={game}
					companyData={companyData}
					groups={this.state.groups}
					unlockGameStep={this.unlockGameStep}
					goToGamePhase={this.goToGamePhase}
					pauseGame={this.pauseGame}
					handleGoToPage={this.handleGoToPage}
					handleToggleFullscreen={this.props.handleToggleFullscreen}
					handleLogout={this.props.handleLogout}
				/>
			);
		default:
			return <div>Unknown page: {this.state.page}</div>;
		}
	}
}

FacilitatorController.propTypes = {
	isFullscreen: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default FacilitatorController;
