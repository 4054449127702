import React,  { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { decisionToolsData } from 'data/tools-data';
import { generalUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {renderMarkdown} from 'helpers/text-helper';
import {checkIfConditionsAreMet} from 'helpers/group-game-helper';
import {getText} from 'helpers/language-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import './decision-making.scss';

const DecisionMaking = (props) => {
	let {
		group,
		decisionTool,
		handleGoToPage,
		handleInputText,
		confirmAndContinue,
		gameIsPaused,
		toggleInfoPopup,
		game
	} = props;

	/* Get data for this game's decision tool  */
	let decisionToolData = decisionToolsData.find((tool) => {return tool.id === decisionTool;});
	const scenarioData = gamesData[game.type].scenarios.find((sc) => {return sc.id === game.scenario;});
	const eventCards = scenarioData.eventCards;
	const threatsData = scenarioData.threatsData;

	/* Confirm button status */
	const [confirmBtnIsDisabled, setConfirmBtnIsDisabled] = React.useState(false);	
	useEffect(() => {
		setConfirmBtnIsDisabled(false);
		if (decisionToolData && decisionToolData.questions && group.hasOwnProperty('decisionAnswers')) {
			decisionToolData.questions.forEach((question) => {
				if (group.decisionAnswers.length === 0) { setConfirmBtnIsDisabled(true); };
				group.decisionAnswers.forEach((answer) => { 
					if (answer.id === question.id && answer.value === '') {
						setConfirmBtnIsDisabled(true);
					};
				});
			});
			if (group.decisionAnswers.length !== decisionToolData.questions.length) {
				setConfirmBtnIsDisabled(true);
			}
		} else if (group.decisionAnswers && group.decisionAnswers.length === 0) {
			setConfirmBtnIsDisabled(true);
		} 
	}, [decisionToolData, group]);
	
	/* Briefing text */
	const briefing = scenarioData.endgameBriefing[game.languageId];

	/* Incident text */
	let incidentText = null;
	if (group.callFacilitatorCardId) {
		const cardData = eventCards.find((c) => {return c.id === group.callFacilitatorCardId;});
		if (cardData) {
			let consequences = null;
			/* Default consequenses */
			if (cardData.consequences) {
				consequences = cardData.consequences;
			}
			/* Option consequenses */
			if (cardData.options && cardData.options.length > 0) {
				let selectedOptionId = null;
				for (const [, value] of Object.entries(group.gameStepCards)) {
					let groupCard = value.cards.find((card) => {
						return (
							card && 
							card.id ===  group.callFacilitatorCardId && 
							card.hasOwnProperty('selectedOptionId')
						);
					});
					if (groupCard) selectedOptionId = groupCard.selectedOptionId;
					if (selectedOptionId) break;
				}
				if (selectedOptionId) {
					let selectedOption = cardData.options.find((o) => {return o.id === selectedOptionId;});
					if (selectedOption) consequences = selectedOption.consequences;
				}
			}
			if (consequences) {
				incidentText = getText(consequences.text, game.languageId);
				if (consequences.conditionals) {
					const gameSteps = gamesData[game.type].gameSteps;
					let applyConditionalEffects = false;
					consequences.conditionals.forEach((conditional) => {
						/* A conditional effect has already been matched */
						if (applyConditionalEffects) return;
			
						/* Check all conditions for conditional */
						if (conditional && conditional.conditions) {
							let conditionsAreMet = checkIfConditionsAreMet(
								conditional.conditions, 
								group.selectedCrew, 
								group.dirtyDozenValues, 
								group.threats,
								group.gameStep, 
								group.gameStepCards,
								gameSteps
							);
							if (conditionsAreMet) {
								applyConditionalEffects = true;
								incidentText = getText(conditional.text, game.languageId);
							}
						}
					});
				}
			}
		}
	}

	/* Triggered threats text */
	let threats =  `${group.threats.map((threat) => {
		let threatName = getText(threatsData.find((t) => {return t.id === threat;}).title, game.languageId);
		if (threatName) return `${threatName}`;
		return `${threat}`;
	}).join(', ')}`;
			

	/* Load and store answer values locally */
	const [answers, setAnswers] = useState([]);
	useEffect(() => {
		if (decisionToolData && decisionToolData.questions) {
			let initAnswersArray = [];
			decisionToolData.questions.forEach((question) => {
				/* Answer template  */
				let initAnswer = {id: question.id, value: ''};
				/* Group answer */		
				const groupAnswer = group.decisionAnswers.find((a) => {return (a.id === question.id);});
				if (groupAnswer && groupAnswer.value) initAnswer.value = groupAnswer.value;
				initAnswersArray.push(initAnswer);
			});
			setAnswers(initAnswersArray);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [decisionToolData]);

	/* Update locally stored answers when props are updated */
	useEffect(() => {
		setAnswers((answers) => {
			let newAnswers = JSON.parse(JSON.stringify(answers));
			decisionToolData.questions.forEach((question) => {
				const newAnswerIndex = newAnswers.findIndex((a) => {return a.id === question.id;});
				const groupAnswer = group.decisionAnswers.find((a) => {return (a.id === question.id);});		
				if (newAnswerIndex >= 0 && groupAnswer) {
					/* Only overwrite if local version is different */
					if (newAnswers[newAnswerIndex].value !== groupAnswer.value) {
						newAnswers[newAnswerIndex].value = groupAnswer.value;
					}
				}
			});
			return newAnswers;
		});
	}, [decisionToolData.questions, group.decisionAnswers]);

	/* Update locally stored answers & database when user is typing */
	const handleTextChange = (e, id) => {
		setAnswers((answers) => {
			let newAnswers = JSON.parse(JSON.stringify(answers));
			answers.forEach((answer, i) => {
				if (answer.id === id) newAnswers[i].value = e.target.value;
			});
			return newAnswers;
		});
		handleInputText(e);
	};

	return (
		<div className="DecisionMaking">
			<Logo onClick={() => {handleGoToPage('welcome');}}
			/>
			<ProgressBar 
				gameType={game.type}
				title={getText(decisionToolData.title, game.languageId)} 	
				linkText={getText(generalUiTexts.flightInfo, game.languageId)}
				toggleInfo={toggleInfoPopup}
			/>
			<Button
				text={gameIsPaused 
					? getText(generalUiTexts.paused, game.languageId) 
					: getText(generalUiTexts.submit, game.languageId)
				}
				classes={gameIsPaused ? ['paused'] : ['next']}
				isDisabled={gameIsPaused ? true : confirmBtnIsDisabled}
				onClick={() => {confirmAndContinue('decision-making');}}
			/>
			<div className="DecisionMaking-container">
				<div className="DecisionMaking-form">
					<div className={'DecisionMaking-header'}>{getText(decisionToolData.title, game.languageId)}</div>
					<div className={'DecisionMaking-questions'}>
						{decisionToolData && decisionToolData.questions && 
						decisionToolData.questions.map((question) => {
							let answer = null;
							let decisionAnswer = (answers 
								? answers.find((answer) => {return answer.id === question.id;})
								: null
							);
							if (decisionAnswer && decisionAnswer.value) answer = decisionAnswer.value;
							return (
								<div key={question.id} className="DecisionMaking-question">
									<div className="DecisionMaking-questionTitle">
										{renderMarkdown(getText(question.title, game.languageId))}
									</div>
									<div className="DecisionMaking-answer">
										<textarea
											id={question.id}
											className="DecisionMaking-textarea"
											name={question.id}
											placeholder={
												getText(generalUiTexts.reflection.textPlaceholder, game.languageId)
											}
											rows="5"
											value={answer ? answer : ''}
											onChange={(event) => {handleTextChange(event, question.id);}}
										></textarea>
									</div>
								</div>
							);
						})}
						{!decisionToolData && <p>Unknown reflection</p>}
					</div>
				</div>
				<div className="DecisionMaking-overview">
					{incidentText && <div className="incident">
						<p className="incident-title">{getText(generalUiTexts.reflection.incident, game.languageId)}</p>
						<div className="incident-content">{renderMarkdown(incidentText)}</div>
					</div>}
					{threats && <div className="threat">
						<p className="threat-title">{getText(generalUiTexts.threats, game.languageId)}:</p>
						<div className="threat-content">{threats}</div>
					</div>}

					<div className="brief" >{renderMarkdown(briefing)}</div>
				</div>
			</div>
		</div>
	);
};

DecisionMaking.propTypes = {
	group: PropTypes.object.isRequired,
	decisionTool: PropTypes.string.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleInputText: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	gameIsPaused: PropTypes.bool.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
	game: PropTypes.object.isRequired,
};

export default DecisionMaking;
