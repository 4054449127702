import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {generalUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {
	moveStaffCard,
	checkIfSelectedOptionsConfirmed, 
	getConfirmButtonData, 
	getIndexOfNextUnresolvedCard
} from 'helpers/group-game-helper-st';
import {getText} from 'helpers/language-helper';
import ProgressBar from 'components/progress-bar/progress-bar';
import Button from 'components/button/button';
import GameBoardStat from 'components/game-board-stat/game-board-stat';
import CrewCardSTFlippable from 'components/crew-card-st/crew-card-st-flippable';
import EventCardST from 'components/event-card-st/event-card-st';
import GameBoardSTDnDContainer from './game-board-st-dnd-container';
import GameBoardSTDnDDragLayer from './game-board-st-dnd-drag-layer';
import './game-board-st.scss';


const GameBoardST = (props) => {
	const {
		isFacilitator,
		delayDealingCards,
		isConfirmingSelectedOptions,
		languageId,
		gameType,
		gameScenario,
		group,
		gamePhase,
		resolvedEventCardIndex,
		toggleInfoPopup,
		toggleDirtyDozenPopup,
		handleGoToPage,
		handleMoveCrew,
		handleConfirmSelectedOptions,
		handleApplyEventCardEffects,
		confirmAndContinue,
		gameIsPaused
	} = props;

	/**
	 * Get game step and data
	 */
	// Get game steps, crew data, scenario data and current game step
	const gameSteps = gamesData[gameType].gameSteps;
	const crewData = gamesData[gameType].crewData;
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	let [gameStep, setGameStep] = useState(group.gameStep ? group.gameStep : 'day-1');

	// Go to next game step (if next game step also has the page 'game-board')
	useEffect(() => {
		if (group.gameStep !== gameStep) {
			let newGameStepData = gameSteps.find((step) => {return step.id === group.gameStep;});
			/* Check if next step has the gameboard as its page */
			if (newGameStepData && newGameStepData.page === 'game-board') {
				/* Check that the game step actually has cards to display */
				if (group.gameStepCards && group.gameStepCards[gameStep] && group.gameStepCards[gameStep].cards) {
					setGameStep(group.gameStep);
				}
			}
		}
	}, [gameStep, gameSteps, group.gameStep, group.gameStepCards]);

	// Get game step data and event cards for current game step
	let gameStepData = gameSteps.find((step) => {return step.id === gameStep;	});
	let dayData = scenarioData.daysData.find((d) => {return d.gameStep === gameStep;});
	let eventCards = (dayData && dayData.cards ? dayData.cards : []);

	// Time, risk & cost values 
	let [timeValue, setTimeValue] = useState(group.timeValue ? group.timeValue : 0);
	let [riskValue, setRiskValue] = useState(group.riskValue ? group.riskValue : 0);
	let [costValue, setCostValue] = useState(group.costValue ? group.costValue : 0);


	/**
	 * Page loaded: check if cards are ready to be displayed, deal them
	 */
	// Check if cards are ready to be displayed
	let displayCards = (
		!delayDealingCards && 
		group.gameStepCards && group.gameStepCards[gameStep] &&
		group.gameStepCards[gameStep].hasOwnProperty('cards') && group.gameStepCards[gameStep].cards.length > 0
	);

	// Dealing cards animation
	const [dealThem, setDealThem] = useState(false);
	const [animationFinished, setAnimationFinished] = useState(false);
	useEffect(() => {
		setDealThem(false);
		setAnimationFinished(false);
		if (displayCards) {
			setTimeout(() => {
				setDealThem(true);
			}, 500);
			setTimeout(() => {
				setAnimationFinished(true);
			}, 3500);
		}
	}, [displayCards]);


	/**
	 * Not all staff members placed, shake staff icons randomly
	 */
	// Count number of staff members placed
	let numberOfEventCards = group.gameStepCards[gameStep].cards.length;
	let numberOfStaffMembersPlaced = group.gameStepCards[gameStep].cards.filter((c) => {
		return (c.hasOwnProperty('selectedCrewMemberId') && c.selectedCrewMemberId !== null);
	}).length;

	// Shake timer, card to shake index, excluded cards 
	const [nextShake, setNextShake] = useState(0);
	const [cardIndexToShake, setCardIndexToShake] = useState(5);

	// If use drags a staff member, reset drag inactivity listener
	const resetDraggingInactivity = () => {
		if ( nextShake !== 0 ) {
			setNextShake(0);
			setCardIndexToShake(5);
		}
	};

	// Timer for next card to shake
	useEffect(() => {
		let interval = null;
		if (displayCards) {
			if (numberOfStaffMembersPlaced < numberOfEventCards) {
				interval = setInterval(() => {
					setNextShake((seconds) => {return seconds + 1; });
				}, 3000);
			}
		}
		return () => { if (interval) clearInterval(interval); };
	}, [displayCards, numberOfEventCards, numberOfStaffMembersPlaced]);
	
	/**
	 * Random card to shake picker 
	 */
	useEffect(() => {
		let availableCardsToShake = group.selectedCrew.filter((crew, index) => {
			return (
				index !== cardIndexToShake && 
				!group.gameStepCards[gameStep].cards.some((card) => {
					return (card.selectedCrewMemberId === crew.id);
				})
			);
		});
		if (nextShake > 1 && availableCardsToShake.length > 0) {
			let randomAvailableIndex = Math.floor(Math.random() * availableCardsToShake.length);
			let randomIndex = group.selectedCrew.findIndex((c) => {
				return c.id === availableCardsToShake[randomAvailableIndex].id;
			});
			setCardIndexToShake(randomIndex);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextShake]);
	


	/** 
	 * Placing staff members on event cards
	 */
	// Find card container (in 'available' or on an option)		
	const handleFindCard = (cardId) => {
		let cardContainer = 'available';
		let card = group.gameStepCards[gameStep].cards.find((card) => {
			return card.selectedCrewMemberId === cardId;
		});
		if (card) {
			cardContainer = 'option-' + card.id + '-' + card.selectedOptioniI;	
		}
		return cardContainer;
	};

	// Move crew card
	const handleMoveCard = (cardId, containerId) => {
		if (isFacilitator) return;
		let newGameStepCards = moveStaffCard(cardId, containerId, gameStep, group, handleFindCard);
		handleMoveCrew(newGameStepCards);
	};

	/**
	 * Applying event card effects
	 */
	// Check if selected options have been confirmed
	let selectedOptionsConfirmed = checkIfSelectedOptionsConfirmed(group);

	// Get index of next unresolved card	 
	let indexOfNextCard = getIndexOfNextUnresolvedCard(gameStep, group.gameStepCards);
	let [nextCardIndex, setNextCardIndex] = useState(indexOfNextCard);
	useEffect(() => {
		if (nextCardIndex !== indexOfNextCard) {
			if (nextCardIndex < 0) {
				setNextCardIndex(indexOfNextCard);
			} else {
				const timer = setTimeout(() => {
					setNextCardIndex(indexOfNextCard);
				}, 1000); // flip animation lasts 800 ms 
				return () => {return clearTimeout(timer);};
			}
		}
	}, [indexOfNextCard, nextCardIndex]);

	// Delay showing an event card's effect until it after it has completed flipping
	useEffect(() => {
		let newTimeValue = (group.timeValue ? group.timeValue : 0);
		let newRiskValue = (group.riskValue ? group.riskValue : 0);
		let newCostValue = (group.costValue ? group.costValue : 0);
		
		if (newTimeValue !== timeValue || newRiskValue !== riskValue || newCostValue !== costValue) {
			const timer = setTimeout(() => {
				setTimeValue(newTimeValue);
				setRiskValue(newRiskValue);
				setCostValue(newCostValue);
			}, 1000); // flip animation lasts 800 ms 
			return () => {return clearTimeout(timer);};
		}
	}, [group.timeValue, group.riskValue, group.costValue, timeValue, riskValue, costValue]);


	/**
	 * Animating card effects (on just resolved event card or when hovering)
	 */
	// Check if a new card has just been resolved, animate its effects
	let [newEffectsCrewId, setNewEffectsCrewId] = useState(null);
	let [newEffectTypes, setNewEffectTypes] = useState([]);
	let [newStatDiffs, setNewStatDiffs] = useState(null);
	useEffect(() => {
		if (resolvedEventCardIndex >= 0) {
			// scroll to top of event cards
			let cardContainer = document.getElementById('mainContent');
			if (cardContainer) cardContainer.scrollTop = 0;

			// update new effect trackers
			let card = group.gameStepCards[gameStep].cards[resolvedEventCardIndex];
			setNewEffectsCrewId(card.selectedCrewMemberId);
			setNewEffectTypes(card.effectTypes ? card.effectTypes : []);
			setNewStatDiffs(card.statDiffs ? card.statDiffs : null);
		} else {
			// reset new effect trackers
			setNewEffectsCrewId(null);
			setNewEffectTypes([]);
			setNewStatDiffs(null);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resolvedEventCardIndex]);

	// If hovering over a resolved event card, get the id of the crew member placed on it
	let [eventCardHoverCrewId, setEventCardHoverCrewId] = useState(null);
	// Show applied card effects when hovering over a resolved card
	let [animatedCardEffects, setAnimatedCardEffects] = useState([]);
	let [animatedStatDiffs, setAnimatedStatDiffs] = useState(null);
	const handleAnimateCardEffects = (effectTypes, statDiffs) => {
		if (!isFacilitator) {
			setAnimatedCardEffects(effectTypes);
			setAnimatedStatDiffs(statDiffs);
		};
	};

	// Animate stats changed
	let animateTimeStats = false;
	let animateRiskStats = false;
	let animateCostStats = false;
	let animateStatDiffs = null;
	if (animatedCardEffects.length > 0) {
		// Hover effect (first priorty)
		animateTimeStats = (
			animatedCardEffects.indexOf('time') >= 0 && animatedStatDiffs && animatedStatDiffs.hasOwnProperty('time')
				? true : false
		);
		animateRiskStats = (
			animatedCardEffects.indexOf('risk') >= 0 && animatedStatDiffs && animatedStatDiffs.hasOwnProperty('risk')
				? true : false
		);
		animateCostStats = (
			animatedCardEffects.indexOf('cost') >= 0 && animatedStatDiffs && animatedStatDiffs.hasOwnProperty('cost')
				? true : false
		);
		animateStatDiffs = animatedStatDiffs;
	} else {
		// New card resolved effect (second priority)
		animateTimeStats = (
			newEffectTypes.indexOf('time') >= 0 && newStatDiffs && newStatDiffs.hasOwnProperty('time')
				? true : false
		);
		animateRiskStats = (
			newEffectTypes.indexOf('risk') >= 0 && newStatDiffs && newStatDiffs.hasOwnProperty('risk')
				? true : false
		);
		animateCostStats = (
			newEffectTypes.indexOf('cost') >= 0 && newStatDiffs && newStatDiffs.hasOwnProperty('cost')
				? true : false
		);
		animateStatDiffs = newStatDiffs;
	}

	/**
	 * Confirm button status, text and action 
	 */
	// Check if all card effects have been applied
	let allCardEffectsApplied = (selectedOptionsConfirmed && indexOfNextCard < 0);

	// Get confirm button data
	let confirmBtnData = getConfirmButtonData(
		gameIsPaused,
		animationFinished,
		languageId,
		group, 
		gamePhase,
		selectedOptionsConfirmed,
		allCardEffectsApplied,
		handleConfirmSelectedOptions, 
		handleApplyEventCardEffects, 
		confirmAndContinue,
		gameSteps,
	);

	return (
		<div className="GameBoardST">
			<GameBoardSTDnDContainer
				gameType={gameType}
				containerId="available"
				cardTypes={crewData.cardTypes}
				cardTypesAccepted={crewData.cardTypes}
				handleFindCard={handleFindCard}
				handleMoveCard={handleMoveCard}
			>
				{/* Game board header */}
				<div className="GameBoardST-header">
					<ProgressBar 
						gameType={gameType}
						page="game-board"
						title={getText(gameStepData.title, languageId)} 
						onClick={() => {handleGoToPage('welcome');}}
					/>

					{/* Extra time */}
					<GameBoardStat
						animate={animateTimeStats}
						statDiff={animateStatDiffs ? animateStatDiffs.time : null}
						type="time"
						title={getText(generalUiTexts.extraTime, languageId)}
						value={timeValue}
						maxValue = {appConfig['st']['timeMax']}
					/>

					{/* Extra risk */}
					<GameBoardStat
						animate={animateRiskStats}
						statDiff={animateStatDiffs ? animateStatDiffs.risk : null}
						type="risk"
						title={getText(generalUiTexts.extraRisk, languageId)}
						value={riskValue}
						maxValue = {appConfig['st']['riskMax']}
					/>

					{/* Extra cost */}
					<GameBoardStat
						animate={animateCostStats}
						statDiff={animateStatDiffs ? animateStatDiffs.cost : null}
						type="cost"
						title={getText(generalUiTexts.extraCost, languageId)}
						value={costValue}
						maxValue = {appConfig['st']['costMax']}
					/>
					<Button
						text={''}
						classes={['st', 'info']}
						onClick={() => {toggleInfoPopup();}}
					/>
					<Button
						text={confirmBtnData.text}
						classes={confirmBtnData.classes.concat(['st'])}
						isDisabled={confirmBtnData.isDisabled}
						isLoading={isConfirmingSelectedOptions}
						onClick={() => {confirmBtnData.action(...confirmBtnData.params);}}
					/>
				</div>

				{/* Crew */}
				<div className="GameBoardST-crew">
					{crewData.crewSlots.map((slot, i) => {
						let selectedCrewMember = group.selectedCrew.find((crew) => {
							return crew.slotId === slot.id;
						});
						let crewId = selectedCrewMember.id;
						let cardData = JSON.parse(JSON.stringify(
							crewData.availableCrew.find((person) => {return person.id === crewId;})
						));
						cardData.color = slot.color;
						cardData.dirtyDozenIds = selectedCrewMember.dirtyDozenIds;
						let isSelected = group.gameStepCards[gameStep].cards.some((c) => {
							return c.selectedCrewMemberId === selectedCrewMember.id;
						});
						let correspondingEventCardResolved = false;
						if (selectedOptionsConfirmed) {
							group.gameStepCards[gameStep].cards.forEach((card) => {
								if (card.selectedCrewMemberId === crewId && card.effectsApplied === true) {
									correspondingEventCardResolved = true;
								}
							});
						}
						let animateDD = false;
						if (eventCardHoverCrewId) {
							/* Hover animation (first priority) */
							animateDD = (
								eventCardHoverCrewId === cardData.id && 
								animatedCardEffects.indexOf('dirtyDozen') >= 0
							);
						} else {
							/* Recently resolved */
							animateDD = (
								newEffectsCrewId === cardData.id &&
								newEffectTypes.indexOf('dirtyDozen') >= 0
							);
						}
						
						return (
							<div key={slot.id} className={'GameBoardST-slot'}>
								<CrewCardSTFlippable
									canFlip={true} 
									isFlipped={true}
									isDraggable={!isSelected && animationFinished && !selectedOptionsConfirmed}
									isSelected={isSelected && !correspondingEventCardResolved}
									animateDD={animateDD}
									languageId={languageId}
									layout="gameboard"
									eventCardHoverCrewId={eventCardHoverCrewId}
									cardData={cardData}
									shake={cardIndexToShake === i}
									toggleDirtyDozenPopup={toggleDirtyDozenPopup}
								/>
							</div>
						);
					})}
				</div>

				{/* Event cards */}
				<div id="mainContent" className="GameBoardST-mainContent">
					<div className={`GameBoardST-cards ${dealThem ? 'cardsDealed' : ''}`}>
						{displayCards &&
						group.gameStepCards[gameStep].cards.map((card, index) => {
							let cardData = null;
							let cardAction = null;
							let cardParams = [];
							let shakeCard = false;
							let effectsApplied = false;
							let cardEffects = null;
							let statDiffs = null;
							if (card) {
								cardData = eventCards.find((c) => {return c.id === card.id;});
								if (cardData) {
									/* Check if effects have been applied */
									effectsApplied = (card.effectsApplied === true);
									cardEffects = card.effectTypes ? card.effectTypes : null;
									statDiffs = card.statDiffs ? card.statDiffs : null;

									if (!effectsApplied && selectedOptionsConfirmed && nextCardIndex === index) {
										/* Card action: Apply effects */
										cardAction = handleApplyEventCardEffects;
										cardParams = [false, index];
										shakeCard = true;
									}
								}
							}
	
							return (
								<div
									key={index}
									className={'GameBoardST-cardWrap' + (shakeCard ? ' shake' : '')}
									onClick={() => {if (cardAction && animationFinished) cardAction(...cardParams);}}
									onMouseOver={() => {
										if (cardEffects) {
											setEventCardHoverCrewId(card.selectedCrewMemberId);
											handleAnimateCardEffects(cardEffects, statDiffs);
										}
									}}
									onMouseOut={() => {
										if (eventCardHoverCrewId) setEventCardHoverCrewId(null);
										if (cardEffects) handleAnimateCardEffects([], null);
									}}	
								>
									{(card && cardData) && 
											<EventCardST
												key={index}
												isDisabled={(nextCardIndex !== index)}
												selectedOptionsConfirmed={selectedOptionsConfirmed}
												effectsApplied={effectsApplied}
												animationFinished={animationFinished}
												languageId={languageId}
												gameType={gameType}
												group={group}
												card={card}
												cardData={cardData}
												cardTypes={crewData.cardTypes}
												selectedCrew={group.selectedCrew}
												handleMoveCard={handleMoveCard}
												handleFindCard={handleFindCard}
											/>
									}
								</div>
							);
						})}
					</div>				
				</div>
			</GameBoardSTDnDContainer>
			<GameBoardSTDnDDragLayer 
				crewData={crewData} 
				selectedCrew={group.selectedCrew} 
				resetDraggingInactivity={resetDraggingInactivity}
			/>
		</div>
	);
};

GameBoardST.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	gameScenario: PropTypes.string.isRequired,
	isFacilitator: PropTypes.bool.isRequired,
	delayDealingCards: PropTypes.bool.isRequired,
	isConfirmingSelectedOptions: PropTypes.bool.isRequired,
	gameIsPaused: PropTypes.bool.isRequired,
	group: PropTypes.object.isRequired,
	gamePhase: PropTypes.number.isRequired,
	resolvedEventCardIndex: PropTypes.number.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
	toggleDirtyDozenPopup: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleMoveCrew: PropTypes.func.isRequired,
	handleConfirmSelectedOptions: PropTypes.func.isRequired,
	handleApplyEventCardEffects: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired
};

export default GameBoardST;