const gamesArray = [
	// 'crm-aeroplanes', 'crm-helicopters', 'safetytraining'
	'crm-helicopters'
];

const gamesData = {
	'crm-helicopters': {
		urlPath: '/crm-helicopters',
		title: {
			en: 'CRM Training Concept'
		},
		subtitle: {
			en: 'HELICOPTER'
		},
		titleShort: {
			en: 'CRM-H'
		},
		titleLandingPage: {
			en: 'NAVY'
		},
		titleOverview: {
			en: 'NAVY'
		},
		welcomeText: {
			en: require('data/crm-helicopters/texts/welcome_en.md').default
		},	
		crewInfoText: {
			en: require('data/crm-helicopters/texts/crew-info_en.md').default
		},
		goodbyeText: {
			en: require('data/crm-helicopters/texts/goodbye_en.md').default
		},
		gamePhases: require('data/crm-helicopters/game-phases').gamePhases,
		gameSteps: require('data/crm-helicopters/game-steps').gameSteps,
		crewData: require('data/crm-helicopters/crew-data').crewData,
		reflectionsData: require('data/crm-helicopters/reflections-data').reflectionsData,
		scenarios: [
			{
				id: 'navy',
				title: {
					en: 'Navy'
				},
				route: 'navy',
				languageIds: ['en'],
				eventCards: require('data/crm-helicopters/navy/event-cards-data').eventCards,
				threatsData: require('data/crm-helicopters/navy/threats-data').threatsData,
				locations: [
					{id: 0, type: 'base', title: {en: 'SHIP'}, text: {
						en: require('data/crm-helicopters/navy/locations/ship_en.md').default
					}},
					{id: 1, type: 'other', title: {en: 'HELO'}, text: {
						en: require('data/crm-helicopters/navy/locations/helo_en.md').default
					}},
					{id: 2, type: 'other', title: {en: 'TARGET'}, text: {
						en: require('data/crm-helicopters/navy/locations/target_en.md').default
					}},
					{id: 3, type: 'other', title: {en: 'AD # 1'}, text: {
						en: require('data/crm-helicopters/navy/locations/ad_1_en.md').default
					}},
					{id: 4, type: 'other', title: {en: 'AD # 2'}, text: {
						en: require('data/crm-helicopters/navy/locations/ad_2_en.md').default
					}}
				],
				briefings: [
					{id: 0, gameStepId: null, text: {
						en: require('data/crm-helicopters/navy/briefings/briefing-0_en.md').default
					}},
					{id: 1, gameStepId: 'planning', text: {
						en: require('data/crm-helicopters/navy/briefings/briefing-1_en.md').default
					}},
					{id: 2, gameStepId: 'preparation', text: {
						en: require('data/crm-helicopters/navy/briefings/briefing-2_en.md').default
					}},
					{id: 3, gameStepId: 'start', text: {
						en: require('data/crm-helicopters/navy/briefings/briefing-3_en.md').default
					}},
					{id: 4, gameStepId: 'flight', text: {
						en: require('data/crm-helicopters/navy/briefings/briefing-4_en.md').default
					}},
					{id: 5, gameStepId: 'landing', text: {
						en: require('data/crm-helicopters/navy/briefings/briefing-5_en.md').default
					}}
				]
			},
		]
	},
};

module.exports = {gamesArray, gamesData};