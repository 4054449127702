import React from 'react';
import PropTypes from 'prop-types';
import { gamesData} from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import CrewCardSTFlippable from 'components/crew-card-st/crew-card-st-flippable';
import './crew-feedback-st.scss';

const CrewFeedbackST = (props) => {
	let {
		game,
		group,
		handleGoToPage,
		handleSelectSlotST,
		confirmAndContinue,
		toggleInfoPopup,
		toggleDirtyDozenPopup
	} = props;

	/* Game step data */
	const gameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === 'crew-feedback';});

	/* Crew data */
	const crewData = gamesData[game.type].crewData;

	/* Find first crewmember for which effects have not been applied */
	let nextCrewmember = group.selectedCrew.find((c) => {
		return (!c.hasOwnProperty('effectApplied') || c.effectApplied === false);
	});

	/* Prepare selectec card/slot */
	let selectedSlotId = null;
	if (group.activeFeedbackCrewId) {
		let selectedCrewMember = group.selectedCrew.find((crewMember) => {
			return (crewMember.id === group.activeFeedbackCrewId);
		});
		if (selectedCrewMember) selectedSlotId = selectedCrewMember.slotId;
	}

	/* Confirm button status */
	let allCardsFlipped = (group.selectedCrew.filter((crew) => {
		return (crew.effectApplied && crew.effectApplied === true);
	}).length === group.selectedCrew.length);
	let confirmBtnData = getConfirmButtonData(
		allCardsFlipped, 
		game, 
		'crew-feedback', 
		getText(generalUiTexts.next, game.languageId)
	);
	if (game.type === 'safetytraining') confirmBtnData.classes.push('st');
	if (!confirmBtnData.isDisabled) confirmBtnData.classes.push('animate');

	
	return (
		<React.Fragment>
			<div className="CrewFeedbackST">
				<ProgressBar 
					gameType={game.type}
					title={getText(gameStepData.title, game.languageId)} 
					subtitle={gameStepData.subtitle ? getText(gameStepData.subtitle, game.languageId) : null} 
					onClick={() => {handleGoToPage('welcome');}}
				/>
				<Button
					text={''}
					classes={['st', 'info']}
					onClick={() => {toggleInfoPopup();}}
				/>
				<Button 
					isDisabled={confirmBtnData.isDisabled}
					text={confirmBtnData.text}
					classes={confirmBtnData.classes}
					onClick={() => {confirmAndContinue('crew-feedback');}}
				/>
				<div className="CrewFeedbackST-crew">
					{crewData.crewSlots.map((slot) => {
						let selectedCrewMember = group.selectedCrew.find((crew) => {return crew.slotId === slot.id;});
						let crewId = selectedCrewMember.id;
						let cardData = JSON.parse(JSON.stringify(
							crewData.availableCrew.find((person) => {return person.id === crewId;})
						));
						cardData.color = slot.color;
						cardData.dirtyDozenIds = 
							(selectedCrewMember.dirtyDozenIds ? selectedCrewMember.dirtyDozenIds : []);
						return (
							<div 
								key={slot.id} 
								className={'CrewFeedbackST-slot' + 
									(nextCrewmember && nextCrewmember.id === crewId && slot.id !== selectedSlotId
										? ' shake' 
										: ''
									)
								}
							>							
								<CrewCardSTFlippable
									canFlip={(selectedCrewMember.effectApplied === true ? true : false)}
									isDraggable={false}
									isSelected={false}
									languageId={game.languageId}
									layout={(selectedCrewMember.effectApplied === true ? 'showBack' : null)}
									cardData={cardData}
									onClick={(selectedCrewMember.effectApplied === true 
										? null
										: () => {handleSelectSlotST(slot.id);}
									)}
									toggleDirtyDozenPopup={toggleDirtyDozenPopup}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
};

CrewFeedbackST.propTypes = {
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleSelectSlotST: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
	toggleDirtyDozenPopup: PropTypes.func.isRequired,
};

export default CrewFeedbackST;