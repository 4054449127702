const threatsData = [
	{id: 'fatigue', eventCardId: 1, title: {en: 'Fatigue'}},
	{id: 'communication', eventCardId: 1, title: {en: 'Communication'}},
	{id: 'ice', eventCardId: 2, title: {en: 'Narrow Water / Ice'}},
	{id: 'fuel', eventCardId: 4, title: {en: 'Fuel'}},
	{id: 'delay', eventCardId: 5, title: {en: 'Delay'}},
	{id: 'stress', eventCardId: 8, title: {en: 'Stress'}},
	
	{id: 'oil-spillage', eventCardId: 17, title: {en: 'Oil Spillage'}},
	{id: 'night', eventCardId: 18, title: {en: 'Night'}},
	{id: 'limited-maneuverability', eventCardId: 19, title: {en: 'Limited Maneuverability'}},
	{id: 'water-only', eventCardId: 22, title: {en: 'Water Only'}},

	// critical events
	{id: 'lack-of-communication', eventCardId: 101, title: {en: 'Lack of Communication'}},
	{id: 'lack-of-resources', eventCardId: 102, title: {en: 'Lack of Resources'}},
	{id: 'stress-2', eventCardId: 104, title: {en: 'Stress'}},
	{id: 'refueling2', eventCardId: 105, title: {en: 'Refueling'}},
];

export {threatsData};