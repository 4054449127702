import React from 'react';
import PropTypes from 'prop-types';
import { renderMarkdown } from 'helpers/text-helper';
import { dirtyDozenData } from 'data/dirty-dozen-data';
import { skillsData } from 'data/skills-data';
import { gamesData} from 'data/games-data';
import {generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import './event-card-st-back.scss';

const EventCardSTBack = ({ isDisabled, languageId, gameType, card, cardData, selectedCrew}) => {
	/* Get crew member */
	const crewData = gamesData[gameType].crewData;
	let crewMemberData = crewData.availableCrew.find((c) => {return c.id === card.selectedCrewMemberId;});
	let selectedCrewMemberData = selectedCrew.find((c) => {return c.id === card.selectedCrewMemberId;});

	const getConditionTitle = (effect) => {
		let conditionTitle = null;

		if (effect.conditionType === 'dirtyDozen') {
			let ddId = selectedCrewMemberData.dirtyDozenIds.find((ddId) => {
				return effect.conditionIds.indexOf(ddId) >= 0;
			});
			let ddData = dirtyDozenData.find((dd) => {return dd.id === ddId;});
			conditionTitle = getText(ddData.title, languageId);
		}

		if (effect.conditionType === 'skill') {
			let sId = crewMemberData.skillIds.find((sId) => {
				return effect.conditionIds.indexOf(sId) >= 0;
			});
			conditionTitle = getText(skillsData[sId].title, languageId);
		}

		return conditionTitle;
	};


	/* Selected option & effects */
	let selectedOptionData = cardData.options
		? cardData.options.find((option) => {return option.id === card.selectedOptionId;})
		: null;
	let goodEffects = [];
	let badEffects = [];
	let ddEffects = [];
	let ddBonusEffects = [];

	if (
		selectedOptionData && selectedOptionData.consequences.effects && 
		card.effectIndexes && card.effectIndexes.length > 0
	) {
		selectedOptionData.consequences.effects.forEach((effect, index) => {
			if (card.effectIndexes.indexOf(index) >= 0) {
				let effectObj = JSON.parse(JSON.stringify(effect));

				if (effect.type === 'dirtyDozen') {
					/* Default: first dirty dozen of this type */
					let ddData = dirtyDozenData.find((dd) => {return dd.id === effect.dirtyDozenId;});
					effectObj.title = getText(ddData.title, languageId);
					effectObj.text = getText(crewMemberData.name, languageId) + ' ' + 
						getText(generalUiTexts.newDirtyDozen, languageId) + ':';
					if (
						selectedCrewMemberData.dirtyDozenIds.filter((ddId) => {
							return ddId === effect.dirtyDozenId;
						}).length === 2
					) {
						/* Second dirty dozen of this type */
						effectObj.title = getText(ddData.title, languageId);
						effectObj.text = getText(crewMemberData.name, languageId) + ' ' + 
							getText(generalUiTexts.extraDirtyDozen, languageId);
						effectObj.text = effectObj.text.replace(/%dd%/g, '<span>' + effectObj.title + '</span>');
					}
					if (
						selectedCrewMemberData.dirtyDozenIds.filter((ddId) => {
							return ddId === effect.dirtyDozenId;
						}).length > 2
					) {
						/* Third or fourth dirty dozen of this type */
						effectObj.title = getText(ddData.title, languageId);
						effectObj.text = getText(crewMemberData.name, languageId) + ' ' + 
							getText(generalUiTexts.multiDirtyDozen, languageId);
						effectObj.text = effectObj.text.replace(/%dd%/g, '<span>' + effectObj.title + '</span>');
						ddBonusEffects = [
							{type: 'risk', value: 1, title: getText(generalUiTexts.extraRisk, languageId)},
							{type: 'cost', value: 1, title: getText(generalUiTexts.extraCost, languageId)}
						];
					}
					ddEffects.push(effectObj);
				}

				let conditionTitle = null;
				if (effect.conditionType) conditionTitle = getConditionTitle(effect);
			

				if (effect.type === 'time') {
					effectObj.title = getText(generalUiTexts.extraTime, languageId);
					if (effect.value > 0) {
						effectObj.text = getText(generalUiTexts.taskExtraTime, languageId) + ':';
						if (conditionTitle) {
							effectObj.text = 
								<span>
									{getText(crewMemberData.name, languageId) + ' '} 
									{getText(generalUiTexts.has, languageId) + ' '}
									<strong>{conditionTitle}</strong>:
								</span>;
						}
						badEffects.push(effectObj);
					} else {
						effectObj.text = null;
						if (conditionTitle) {
							effectObj.text = 
								<span>
									{getText(crewMemberData.name, languageId) + ' '}
									{getText(generalUiTexts.is, languageId) + ' '}
									<strong>{conditionTitle}</strong>:
								</span>;
						}
						goodEffects.push(effectObj);
					}
				}

				if (effect.type === 'risk') {
					effectObj.title = getText(generalUiTexts.extraRisk, languageId);
					if (effect.value > 0) {
						effectObj.text = getText(generalUiTexts.taskExtraRisk, languageId) + ':';
						if (conditionTitle) {
							effectObj.text = 
								<span>
									{getText(crewMemberData.name, languageId) + ' '}
									{getText(generalUiTexts.has, languageId) + ' '}
									<strong>{conditionTitle}</strong>:
								</span>;
						}
						badEffects.push(effectObj);
					} else {
						effectObj.text = null;
						if (conditionTitle) {
							effectObj.text = 
								<span>
									{getText(crewMemberData.name, languageId) + ' '}
									{getText(generalUiTexts.is, languageId) + ' '}
									<strong>{conditionTitle}</strong>:
								</span>;
						}
						goodEffects.push(effectObj);
					}
				}

				if (effect.type === 'cost') {
					effectObj.title = getText(generalUiTexts.extraCost, languageId);
					if (effect.value > 0) {
						effectObj.text = getText(generalUiTexts.taskExtraCost, languageId) + ':';
						if (conditionTitle) {
							effectObj.text = 
								<span>
									{getText(crewMemberData.name, languageId) + ' '}
									{getText(generalUiTexts.has, languageId) + ' '}
									<strong>{conditionTitle}</strong>:
								</span>;
						}
						badEffects.push(effectObj);
					} else {
						effectObj.text = null;
						if (conditionTitle) {
							effectObj.text = 
								<span>
									{getText(crewMemberData.name, languageId) + ' '}
									{getText(generalUiTexts.is, languageId) + ' '}
									<strong>{conditionTitle}</strong>:
								</span>;
						}
						goodEffects.push(effectObj);
					}
				}
			}
		});
	}

	return (
		<div className="EventCardSTBack">
			<div className="EventCardSTBack-header">
				<div className="EventCardSTBack-title">{getText(cardData.title, languageId)}</div>
			</div>
			<div className="EventCardSTBack-body">
				{goodEffects.length > 0 && goodEffects.map((effect, index) => {
					return (
						<div key={index} className="EventCardSTBack-effect good">
							<div className="EventCardSTBack-effectText">{effect.text}</div>
							<div className={'EventCardSTBack-effectValue ' + effect.type}>
								<span>{effect.value}</span>
							</div>
							<div className="EventCardSTBack-effectTitle"><span>{effect.title}</span></div>
						</div>
					);
				})
				}
				<div className="EventCardSTBack-option">
					<div className="EventCardSTBack-optionTitle">
						{(selectedOptionData ? getText(selectedOptionData.title, languageId) : null)}
					</div>
					<div className="EventCardSTBack-optionText">
						{selectedOptionData && 
							renderMarkdown(getText(selectedOptionData.consequences.text, languageId))}
					</div>
				</div>
				{badEffects.length > 0 && badEffects.map((effect, index) => {
					return (
						<div key={index} className="EventCardSTBack-effect bad">
							<div className="EventCardSTBack-effectText">{effect.text}</div>
							<div className={'EventCardSTBack-effectValue ' + effect.type}>
								<span>+{effect.value}</span>
							</div>
							<div className="EventCardSTBack-effectTitle"><span>{effect.title}</span></div>
						</div>
					);
				})
				}
				{ddEffects.length > 0 && ddEffects.map((effect, index) => {
					return (
						<div key={index} className="EventCardSTBack-effect dirtyDozen">
							<div className={'EventCardSTBack-effectText'}>{renderMarkdown(effect.text)}</div>
							{effect.title && 
								<div className="EventCardSTBack-effectDD"><span>{effect.title}</span></div>}

							{ddBonusEffects.length > 0 && ddBonusEffects.map((effect, index) => {
								return (
									<div key={index} className="EventCardSTBack-effect bad oneline">
										<div className={'EventCardSTBack-effectValue ' + effect.type}>
											<span>+{effect.value}</span>
										</div>
										<div className="EventCardSTBack-effectTitle"><span>{effect.title}</span></div>
									</div>
								);
							})}
						</div>
					);
				})
				}
			</div>
			{isDisabled && <div className="EventCard-disabledOverlay" />}
		</div>
	);
};

EventCardSTBack.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	card: PropTypes.object.isRequired,
	cardData: PropTypes.object.isRequired,
	selectedCrew: PropTypes.array.isRequired
};

export default EventCardSTBack;
