const languagesData = [
	{
		id: 'en',
		title: 'English'
	},
	// {
	// 	id: 'ja',
	// 	title: '日本人'
	// }
];

export {languagesData};