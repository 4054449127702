import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import './best-practice-card-front.scss';

const BestPracticeCardFront = (props) => {
	const {
		cardData, 
		isDisabled, 
		isSelected, 
		languageId, 
		toggleSelection, 
		bestPracticesCardsConfirmed, 
		showCheckbox = true
	} = props;

	return (
		<div 
			className={`BestPracticeCardFront ${bestPracticesCardsConfirmed ? '' : 'clickable'}`}
			onClick={
				bestPracticesCardsConfirmed ? () => { return null; }
					: () => {
						toggleSelection(cardData);
					}
			}
		>
			<div className="BestPracticeCardFront-inner">
				<div className="BestPracticeCardFront-header">
					<div className="BestPracticeCardFront-title">
						{(cardData && cardData.title) && getText(cardData.title, languageId)}
					</div>
				</div>
				<div className="BestPracticeCardFront-body">
					<div className="BestPracticeCardFront-text">
						{(cardData && cardData.textFront) && renderMarkdown(getText(cardData.textFront, languageId))}
					</div>
					{showCheckbox && <div className={`BestPracticeCardFront-selectBox 
						${isSelected ? 'selected' : ''}${isDisabled ? 'disabled' : ''}`}
					> 
					</div>}
				</div>
				{isDisabled && <div className="BestPracticeCardFront-disabledOverlay"></div>}
			</div>
		</div>
	);
};

BestPracticeCardFront.propTypes = {
	cardData: PropTypes.object.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	toggleSelection: PropTypes.func,
	showCheckbox: PropTypes.bool,
	isSelected: PropTypes.bool,
	bestPracticesCardsConfirmed: PropTypes.bool.isRequired,
};

export default BestPracticeCardFront;
