import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import './facilitator-games.scss';

const FacilitatorGames = ({games, groups, players}) => {
	return (
		<div className="FacilitatorGames">
			<div className="FacilitatorGames-header">
				<div className="FacilitatorGames-section date">{adminUiTexts.date}</div>
				<div className="FacilitatorGames-section type">{adminUiTexts.game}</div>
				<div className="FacilitatorGames-section scenario">{adminUiTexts.scenario}</div>
				<div className="FacilitatorGames-section phase">{adminUiTexts.currentPhase}</div>
				<div className="FacilitatorGames-section groups">{adminUiTexts.groups}</div>
				<div className="FacilitatorGames-section players">{adminUiTexts.players}</div>
				
			</div>
			<div className="FacilitatorGames-body">
				{games.map((game) => {
					let gameData = (gamesData.hasOwnProperty(game.type) ? gamesData[game.type] : null);
					if (gameData) {
						let gameGroups = groups.filter((g) => {return g.gameId === game.id;});
						let gamePlayers = 0;
						gameGroups.forEach((group) => {
							if (group.players) {
								group.players.forEach((player) => {
									if (players && players.some((p) => {return p.id === player.id;})) {
										gamePlayers += 1;
									}
								});
							}
						});
						let scenarioData = (game.scenario 
							?	gameData.scenarios.find((s) => {return s.id === game.scenario;})
							: null 
						);
						const gamePhaseData = gameData.gamePhases.find((gp) => {return gp.id === game.gamePhase;});
						return (
							<div key={game.id} className="FacilitatorGames-game">
								<div className="FacilitatorGames-section date">{game.playDate}</div>
								<div className="FacilitatorGames-section type">
									{getText(gameData.title, 'en') + 
										(gameData.subtitle ? ' (' + getText(gameData.subtitle, 'en') + ')' : '')}
								</div>
								<div className="FacilitatorGames-section scenario">
									{scenarioData ? getText(scenarioData.title, 'en') : '-'}
								</div>
								<div className="FacilitatorGames-section phase">
									{gamePhaseData ? getText(gamePhaseData.title, 'en') : '-'}
								</div>
								<div className="FacilitatorGames-section groups">{gameGroups.length}</div>
								<div className="FacilitatorGames-section players">{gamePlayers}</div>
							</div>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
};

FacilitatorGames.propTypes = {
	games: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	players: PropTypes.array.isRequired
};

export default FacilitatorGames;