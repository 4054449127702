import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {ReactComponent as StaffIcon} from 'assets/images/icon-staff.svg';
import {ReactComponent as ApprenticeIcon} from 'assets/images/icon-staff-apprentice.svg';
import './crew-dnd-card.scss';

const CrewDndCard = (props) => {
	let {
		isInFocus, 
		isDraggable, 
		isSelected, 
		isDraglayer, 
		isPlaced, 
		languageId,
		layout,
		gameType, 
		cardData, 
		cardTitle, 
		cardColor,
		style, 
		onClick
	} = props;
	const [{ isDragging }, drag, preview] = useDrag({
		type: cardData.cardType,
		item: { id: cardData.id, type: cardData.cardType},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {return (cardData.id === monitor.getItem().id);},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	function renderInfo() {
		return (
			<div className={'CrewDndCard-info' + (languageId === 'ja' ? ' ja' : '')}>
				<div className="CrewDndCard-name"><span>{getText(cardData.name, languageId)}</span></div>
				<div className="CrewDndCard-title">
					{gameType === 'crm-aeroplanes' && <span>{getText(generalUiTexts.title, languageId)}: </span>}
					{cardTitle ? cardTitle : getText(cardData.title, languageId)}
				</div>
				<div className="CrewDndCard-age">
					<span>{getText(generalUiTexts.age, languageId)}: </span>{cardData.age}
				</div>
			</div>
		);
	}

	function renderDescription() {
		return (
			<div className="CrewDndCard-description">
				{renderMarkdown(getText(cardData.description, languageId))}
			</div>
		);
	}

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	const opacity = (((isDragging && !isDraglayer) || isSelected) ? 0.4 : 1);
	let className = 'CrewDndCard ' +
		(gameType === 'safetytraining' ? ' st' : '') +
		(layout ? ' ' + layout : '') +
		(isPlaced ? ' placed' : '') +
		(isInFocus ? ' CrewDndCard--focus' : '') +
		(isDraggable ? ' CrewDndCard--draggable' : '') +
		(isDragging ? ' CrewSelect--dragging' : '');

	let CrewIcon = null;
	if (gameType === 'safetytraining' && isPlaced) {
		if (cardData.type === 'certifying') {
			CrewIcon = StaffIcon;
		} else {
			CrewIcon = ApprenticeIcon;
		}
	}

	return (
		<div ref={drag} style={{opacity, ...style}} className={className} onClick={onClick}>
			<div 
				className={'CrewDndCard-top CrewDndCard-top--' + cardData.type +
					(gameType === 'safetytraining' && isPlaced && cardColor ? ' ' + cardColor : '') +
					(cardData.hasOwnProperty('gender') ? ' ' + cardData.gender : '')
				}
			>
				{CrewIcon && <CrewIcon />}
				{(isInFocus || (gameType === 'safetytraining' && isPlaced) ? renderInfo() : '')}
			</div>

			<div className="CrewDndCard-bottom">
				{(isInFocus || (gameType === 'safetytraining' && isPlaced) ? renderDescription() : renderInfo())}
			</div>
		</div>
	);
};

CrewDndCard.defaultProps = {
	isDraglayer: false,
	isSelected: false,
	style: {},
	onClick: null
};

CrewDndCard.propTypes = {
	isDraggable: PropTypes.bool.isRequired,
	isInFocus: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool,
	isDraglayer: PropTypes.bool,
	isPlaced: PropTypes.bool,
	layout: PropTypes.string,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	cardData: PropTypes.object.isRequired,
	cardTitle: PropTypes.string,
	cardColor: PropTypes.string,
	style: PropTypes.object,
	onClick: PropTypes.func
};

export default CrewDndCard;