const skillsData = {
	experienced: {
		title: {
			en: 'Experienced'
		},
		verb: {
			en: 'is'
		},
	},
	assertive: {
		title: {
			en: 'Assertive'
		},
		verb: {
			en: 'is'
		},
	},
	troubleshooter: {
		title: {
			en: 'Troubleshooter'
		},
		verb: {
			en: 'is a'
		},
	},
	teamplayer: {
		title: {
			en: 'Teamplayer'
		},
		verb: {
			en: 'is a'
		},
	}
};

module.exports = {skillsData};