import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import './create-facilitator-popup.scss';

const CreateFacilitatorPopup = ({setShowCreateFacilitatorPopup, createFacilitator}) => {
	const [facilitatorName, setFacilitatorName] = useState('');
	const [facilitatorEmail, setFacilitatorEmail] = useState('');
	const [isCreatingFacilitator, setIsCreatingFacilitator] = useState(false);
	const [errMsg, setErrMsg] = useState(null);

	const createBtnClass = 'CreateFacilitatorPopup-createBtn' + 
		(isCreatingFacilitator ? ' saving' : '') +
		(facilitatorName.length > 0 && facilitatorEmail.length > 0 ? ' active' : '');


	const handleCreateFacilitator = () => {
		if (isCreatingFacilitator) return;

		setErrMsg(null);
		setIsCreatingFacilitator(true);
		createFacilitator(facilitatorEmail, facilitatorName).then((response) => {
			setIsCreatingFacilitator(false);
			if (response.status === 'success') {
				setShowCreateFacilitatorPopup(false);
			} else {
				setErrMsg(response.error);
			}
		});
	};

	return (
		<div className="CreateFacilitatorPopup">
			<div className="CreateFacilitatorPopup-content">
				<div className="CreateFacilitatorPopup-header">
					<div className="CreateFacilitatorPopup-title">{adminUiTexts.createFacilitator}</div>
					<div 
						className="CreateFacilitatorPopup-closeBtn" 
						onClick={() => {setShowCreateFacilitatorPopup(false);}} 
					/>
				</div>
				<div className="CreateFacilitatorPopup-body">
					<div className="CreateFacilitatorPopup-inputRow">
						<span>{adminUiTexts.name}:</span>
						<input 
							type="text"
							name="name"
							placeholder={adminUiTexts.name.toLowerCase()}
							value={facilitatorName}
							onChange={(event) => {setFacilitatorName(event.target.value);}}
						/>
					</div>
					<div className="CreateFacilitatorPopup-inputRow">
						<span>{adminUiTexts.email}:</span>
						<input 
							type="text"
							name="email"
							placeholder={adminUiTexts.email.toLowerCase()}
							value={facilitatorEmail}
							onChange={(event) => {setFacilitatorEmail(event.target.value);}}
						/>
					</div>
					<div className="CreateFacilitatorPopup-errMsg">{errMsg}</div>

					{/* Create button */}
					<div 
						className={createBtnClass} 
						onClick={() => {handleCreateFacilitator();}}
					>{adminUiTexts.create}</div>		
				</div>
			</div>
		</div>
	);
};



CreateFacilitatorPopup.propTypes = {
	setShowCreateFacilitatorPopup: PropTypes.func.isRequired,
	createFacilitator: PropTypes.func.isRequired
};

export default CreateFacilitatorPopup;