import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {checkIfCanCreateGame, checkIfGameIsAvailable} from 'helpers/game-access-helper';
import {settingsUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import SettingsHeader from './settings-header';
import Button from 'components/button/button';
import DeleteGamePopup from './delete-game-popup';
import './settings.scss';

const Settings = (props) => {
	let {
		showAllGames,
		isDeletingGame,
		showDeleteGamePopup,
		isAdmin,
		languageId,
		userId,
		email,
		gameId,
		companiesData,
		gameType,
		games,
		toggleShowAllGames,
		handleChangeUserLanguage,
		handleGoToGame,
		toggleCreateGamePage,
		toggleDeleteGamePopup,
		handleDeleteGame,
		handleResetGame,
		handleLogout,
		initGame
	} = props;


	/* Sort games by play date and title, filter by facilitatorId */
	let sortedGames = sortArrayByProperty(games, 'playDate', 'DESC', 'title', 'ASC');
	sortedGames = sortedGames.filter((g) => {
		return ((showAllGames || g.facilitatorId === userId));
	});
	let sortedCompanies = sortArrayByProperty(companiesData, 'title', 'ASC');

	/* Check if facilitator has permission to create a game */
	let canCreateGame = (isAdmin 
		? true
		: checkIfCanCreateGame(gameType, (companiesData.length > 0 ? companiesData[0] : null))
	);

	return (
		<div className="Settings">
			{/* Header: create game button, title, email, logout button */}
			<SettingsHeader 
				languageId={languageId}
				page="settings"
				email={email} 
				title={getText(settingsUiTexts.overview, languageId)} 
				gameType={gameType} 
				handleChangeUserLanguage={handleChangeUserLanguage}
				handleLogout={handleLogout}
			>
				{canCreateGame && <Button 
					classes={['createGame']} 
					text={getText(settingsUiTexts.createGame, languageId)} 
					onClick={() => {toggleCreateGamePage();}}
				/>}
			</SettingsHeader>

			{/* Toggle all games button (admin only) */}
			{isAdmin && <div className="Settings-toggleGamesBtn">
				<div 
					onClick={() => {toggleShowAllGames(true);}} 
					className={'Settings-toggleGamesText' + (showAllGames ? ' selected' : '')}
				>{getText(settingsUiTexts.allGames, languageId)}</div>
				<div 
					onClick={() => {toggleShowAllGames(false);}} 
					className={'Settings-toggleGamesText' + (showAllGames ? '' : ' selected')}
				>{getText(settingsUiTexts.myGames, languageId)}</div>
			</div>}

			
			<div className="Settings-games">
				{/* Loop over companies */}
				{sortedCompanies.map((company) => {
					return (
						<div key={company.id} className="Settings-company">
							{(
								sortedCompanies.length > 1 && 
								sortedGames.some((g) => {return g.companyId === company.id;})
							) && <div className="Settings-companyTitle">{company.title}</div>}
							{/* Loop over games */}
							{sortedGames.filter((g) => {return g.companyId === company.id;}).map((game) => {
								let isOwnGame = (userId === game.facilitatorId);
								let isDisabled = !checkIfGameIsAvailable(game, company);
								let isStarted = game?.isStarted;
								return (
									<div 
										key={game.id} 
										className={'Settings-game' + (isStarted ? ' started' : '') + 
											(isDisabled ? ' disabled' : '')}
									>
										{/* Game header: title & play date */}
										<div
											className={'Settings-gameHeader' + (!isOwnGame ? ' dark' : '')}
											onClick={() => {
												if (isStarted && !isDisabled) {toggleCreateGamePage(game.id);}
											}}
										>
											<div className="Settings-gameTitle">
												{game.title ? game.title : game.id}
											</div>
											<div className="Settings-gameDate">
												{game.playDate ? game.playDate : '??-??-??'}
											</div>
										</div>
										{/* Game body: play & edit buttons, game code, reset & delete buttons */}
										<div className="Settings-gameBody">
											<div
												className={'Settings-playBtn' + (isStarted ? ' started' : '')}
												onClick={() => {
													if (!isDisabled) {initGame(game.id); handleGoToGame(game.id);}
												}}
											>
												{isStarted 
													? getText(settingsUiTexts.active, languageId) 
													: getText(settingsUiTexts.play, languageId)
												}
											</div>
											<div
												className={'Settings-editBtn' + (isStarted ? ' started' : '')}
												onClick={() => {if (!isDisabled) toggleCreateGamePage(game.id);}}
											>{getText(settingsUiTexts.edit, languageId)}</div>
											<div className="Settings-gameCode">
												{getText(settingsUiTexts.gameCode, languageId)}: <span>{game.id}</span>
											</div>
											{(appConfig.env === 'development' || appConfig.env === 'test') && 
												<div 
													className="Settings-resetBtn" 
													onClick={() => {handleResetGame(game.id);}}
												>↻</div>
											}
											<div 
												className="Settings-deleteBtn" 
												onClick={() => {toggleDeleteGamePopup(game.id);}}
											/>
										</div>
									</div>
								);
							})}
						</div>
					);
				})}
				
			</div>

			{/* Delete game popup */}
			{(showDeleteGamePopup && gameId) && 
				<DeleteGamePopup 
					isDeletingGame={isDeletingGame}
					languageId={languageId}
					gameId={gameId}
					toggleDeleteGamePopup={toggleDeleteGamePopup}
					handleDeleteGame={handleDeleteGame}
				/>
			}
		</div>
	);
};

Settings.propTypes = {
	showAllGames: PropTypes.bool.isRequired,
	isDeletingGame: PropTypes.bool.isRequired,
	showDeleteGamePopup: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	gameId: PropTypes.string,
	companiesData: PropTypes.array.isRequired,
	gameType: PropTypes.string.isRequired,
	games: PropTypes.array.isRequired,
	toggleShowAllGames: PropTypes.func.isRequired,
	handleChangeUserLanguage: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	toggleCreateGamePage: PropTypes.func.isRequired,
	toggleDeleteGamePopup: PropTypes.func.isRequired,
	initGame: PropTypes.func.isRequired,
	handleDeleteGame: PropTypes.func.isRequired,
	handleResetGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Settings;
