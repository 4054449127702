let dirtyDozenData = [
	{
		id: 1,
		title: {
			en: 'Lack of Communication',
			ja: 'コミュニケーションの欠如'
		},
		text: {
			en: 'Failure to transmit, receive, or provide enough information to complete a task. Never assume anything.<br />Only 30% of verbal communication is received and understood by either side in a conversation. Others usually remember the first and last part of what you say.',
			ja: 'タスクを完了するのに十分な情報を発信、受信、または提供できないこと。決めつけないこと。<br />言語によるコミュニケーションのうち、会話の中でどちらか一方が受け取り、理解できるのはわずか30％です。他者は通常、あなたが言ったことの最初と最後の部分を覚えています。'
		},
		subtitle: {
			en: 'Improve your communication',
			ja: 'コミュニケーション改善'
		},
		bulletpoints: [
			{
				en: 'Say the most important things in the beginning and repeat them at the end.',
				ja: '一番大事なことを最初に言い、最後に繰り返す。'
			},
			{
				en: 'Use checklists.',
				ja: 'チェックリストを使う。'
			}
		]
	},
	{
		id: 2,
		title: {
			en: 'Distraction',
			ja: '注意散漫'
		},
		text: {
			en: 'Anything that draws your attention away from the task at hand.<br />Distractions are the #1 cause of forgetting things, including what has or has not been done in a maintenance task.',
			ja: '目の前の仕事から注意をそらすようなもの。<br />注意散漫は、整備タスクで何をしたのか、していないのかを含めて、物事を忘れることの原因の第1位です。'
		},
		subtitle: {
			en: 'Get back in the groove after a distraction',
			ja: '注意を逸らされたのちに元の状態を取り戻す'
		},
		bulletpoints: [
			{
				en: 'Use checklists.',
				ja: 'チエックリストを使う。'
			},
			{
				en: 'Go back 3 steps when restarting the work.',
				ja: '作業再開時には手順を３ステップ戻る。'
			}
		]
	},
	{
		id: 3,
		title: {
			en: 'Lack of Resources',
			ja: 'リソースの欠如'
		},
		text: {
			en: 'Not having enough people, equipment, documentation, time, parts, etc., to complete a task.',
			ja: 'タスクを完了するために十分な人、機器、文書、時間、部品などがないこと。'
		},
		subtitle: {
			en: 'Improve supply and support',
			ja: '供給と支援の改善'
		},
		bulletpoints: [
			{
				en: 'Order parts before they are required.',
				ja: '部品が必要になる前に注文する。'
			},
			{
				en: 'Have a plan for pooling or loaning parts.',
				ja: '部品のプールや貸し出しの計画をする。'
			}
		]
	},
	{
		id: 4,
		title: {
			en: 'Stress',
			ja: 'ストレス'
		},
		text: {
			en: 'A physical, chemical, or emotional factor that causes physical or mental tension.',
			ja: '身体的、化学的、または感情的な要因で、身体的または精神的な緊張を引き起こすもの。'
		},
		subtitle: {
			en: 'Manage stress before it affects your work',
			ja: '仕事に影響が出る前にストレスを管理する'
		},
		bulletpoints: [
			{
				en: 'Take a rational approach to problem solving.',
				ja: '問題解決に合理的なアプローチをとる。'
			},
			{
				en: 'Take a short break when needed.',
				ja: '必要なときは少し休憩する。'
			},
			{
				en: 'Discuss the problem with someone who can help.',
				ja: '相談できる人に相談する。'
			}
		]
	},
	{
		id: 5,
		title: {
			en: 'Complacency',
			ja: '自己満足'
		},
		text: {
			en: 'Overconfidence from repeated experience performing a task.',
			ja: '同じ作業を繰り返し経験することで得られる過信。'
		},
		subtitle: {
			en: 'Avoid the tendency to see what you expect to see',
			ja: '期待するものを見てしまう傾向を避ける'
		},
		bulletpoints: [
			{
				en: 'Expect to find errors.',
				ja: 'エラーを見つけることを期する。'
			},
			{
				en: 'Don\'t sign it if you did not do it.',
				ja: 'やっていないなら署名しない。'
			},
			{
				en: 'Use checklists.',
				ja: 'チェックリストを使用する。'
			},
			{
				en: 'Learn from the mistakes of others.',
				ja: '他の人のミスから学ぶ。'
			}
		]
	},
	{
		id: 6,
		title: {
			en: 'Lack of Teamwork',
			ja: 'チームワークの欠如'
		},
		text: {
			en: 'Failure to work together to complete a shared goal.',
			ja: '共通の目標を達成するために協力し合うことができないこと。'
		},
		subtitle: {
			en: 'Build solid teamwork',
			ja: '強固なチームワークを築く'
		},
		bulletpoints: [
			{
				en: 'Discuss how a task should be done.',
				ja: 'ある仕事の進め方について話し合う。'
			},
			{
				en: 'Make sure everyone understands and agrees.',
				ja: '全員が理解し、同意していることを確認する。'
			},
			{
				en: 'Trust your teammates.',
				ja: 'チームメイトを信頼する。'
			}
		]
	},
	{
		id: 7,
		title: {
			en: 'Pressure',
			ja: 'プレッシャー'
		},
		text: {
			en: 'Real or perceived forces demanding high-level job performance.',
			ja: '高度な職務遂行を要求する現実の力、または認識された力。'
		},
		subtitle: {
			en: 'Reduce the burden of physical or mental distress',
			ja: '身体的または精神的苦痛の負担を軽減する'
		},
		bulletpoints: [
			{
				en: 'Communicate concerns.',
				ja: '懸念を伝える。'
			},
			{
				en: 'Ask for extra help.',
				ja: '更なる助けを求める。'
			},
			{
				en: 'Put safety first.',
				ja: '安全を第一に考える。'
			}
		]
	},
	{
		id: 8,
		title: {
			en: 'Lack of Awareness',
			ja: '認識不足'
		},
		text: {
			en: 'Failure to recognize a situation, understand what it is, and predict the possible results.',
			ja: '状況を認識し、それが何であるかを理解し、起こりうる結果を予測することの失敗。'
		},
		subtitle: {
			en: 'See the whole picture',
			ja: '全体像をみる'
		},
		bulletpoints: [
			{
				en: 'Make sure there are no conflicts with an existing repair or modifications.',
				ja: '既存の修理や改造と矛盾がないことを確認する。'
			},
			{
				en: 'Fully understand the procedures needed to complete a task.',
				ja: '作業を完了するために必要な手順を完全に理解する。'
			}
		]
	},
	{
		id: 9,
		title: {
			en: 'Lack of Knowledge',
			ja: '知識不足'
		},
		text: {
			en: 'Shortage of the training, information, and/or ability to successfully perform.',
			ja: '成功裏に遂行するための訓練、情報、および/または能力の不足。'
		},
		subtitle: {
			en: 'Don’t guess, know',
			ja: '推測するのではなく、知る'
		},
		bulletpoints: [
			{
				en: 'Use current manuals.',
				ja: '最新のマニュアルを使う。'
			},
			{
				en: 'Ask when you do not know.',
				ja: 'わからないことは聞く。'
			},
			{
				en: 'Participate in training.',
				ja: 'トレーニングに参加する。'
			}
		]
	},
	{
		id: 10,
		title: {
			en: 'Fatigue',
			ja: '疲労'
		},
		text: {
			en: 'Physical or mental exhaustion threatening work performance.',
			ja: '肉体的または精神的な疲労が仕事のパフォーマンスを脅かす。'
		},
		subtitle: {
			en: 'Eliminate fatigue related performance issues',
			ja: '疲労によるパフォーマンスの低下を解消'
		},
		bulletpoints: [
			{
				en: 'Watch for symptoms of fatigue in yourself and others.',
				ja: '自分や他人の疲労の症状に気をつける。'
			},
			{
				en: 'Have others check your work.',
				ja: '自分の仕事を他の人にチェックしてもらう。'
			}
		]
	},
	{
		id: 11,
		title: {
			en: 'Lack of Assertiveness',
			ja: 'アサーティブネスの欠如'
		},
		text: {
			en: 'Failure to speak up or document concerns about instructions, orders, or the actions of others.',
			ja: '指示、命令、他者の行動に関する懸念を口にしなかったり、文書化しなかったりすること。'
		},
		subtitle: {
			en: 'Express your feelings, opinions, beliefs, and needs in a positive, productive manner',
			ja: '自分の気持ち、意見、信念、ニーズを、肯定的かつ生産的に表現する'
		},
		bulletpoints: [
			{
				en: 'Express concerns but offer positive solutions.',
				ja: '懸念を表明しつつも、前向きな解決策を提示する。'
			},
			{
				en: 'Resolve one issue before addressing another.',
				ja: '一つの問題を解決してから、別の問題に対処する。'
			}
		]
	},
	{
		id: 12,
		title: {
			en: 'Norms',
			ja: '規範となるもの'
		},
		text: {
			en: 'Expected, yet unwritten, rules of behaviour.',
			ja: '期待されている、しかし明文化されていない、行動のルール。'
		},
		subtitle: {
			en: 'Help maintain a positive environment with your good attitude and work habits',
			ja: '良い態度と仕事の習慣で、ポジティブな環境の維持に貢献する'
		},
		bulletpoints: [
			{
				en: 'Existing norms do not make procedures right.',
				ja: '既存の規範が正しい手順というわけではない。'
			},
			{
				en: 'Follow good safety procedures.',
				ja: '正しい安全手順に従う。'
			},
			{
				en: 'Identify and eliminate negative norms.',
				ja: 'ネガティブな規範を特定し、排除する。'
			}
		]
	}
];

module.exports = {dirtyDozenData};